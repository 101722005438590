import { Text, VStack, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/empty.json";
const Empty = ({title=''}) => {
  const [isLargerThan450] = useMediaQuery("(min-width: 450px)");
  return (
    <VStack w={"100%"}>
      <Lottie style={isLargerThan450?{width:420,height:420}:{width:250,height:250}} animationData={animationData} loop={true} />
      <Text color={"rgb(220, 20, 60)"} textAlign={'center'} fontSize={['1.37rem','1.7rem','2rem',"2rem"]} fontWeight={700}>
    {title}
      </Text>
    </VStack>
  );
};

export default Empty;
