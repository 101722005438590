import {
    Button,
    Td,
    Tr,
    Tag,
    Input,
    InputRightElement,
    InputGroup,
    useToast
} from "@chakra-ui/react";
import React from "react";
import { FiCopy } from 'react-icons/fi';
const CouponCard = ({ id, couponCode, couponPrice, isExpired, OpenBlockModal, email }) => {
    const toast = useToast();
    const CopyToClipBoard = () => {
        navigator.clipboard
            .writeText(couponCode)
            .then(() => {
                toast({
                    status: "info",
                    title: `couponCode copied`,
                    isClosable: true,
                    duration: 1000,
                    position: "bottom-right",
                });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
                toast({
                    status: "error",
                    title: `errror in copying address`,
                    isClosable: true,
                    duration: 1000,
                });
            });
    };
    return (
        <>
            <Tr>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}> <InputGroup w={["90%"]}>
                    <Input
                        pl={["0.2rem", "0.4rem", "1rem", "1rem"]}
                        fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
                        value={couponCode}
                        readOnly
                    />
                    <InputRightElement>
                        <Button
                            bg={"white !important"}
                            p={"0 !important"}
                            h={"96%"}
                            border={"1px solid gray !important"}
                            onClick={CopyToClipBoard}
                        >
                            <FiCopy />
                        </Button>
                    </InputRightElement>
                </InputGroup></Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}> {couponPrice}</Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}> <Tag colorScheme={isExpired ? "red" : 'green'}>{isExpired ? 'Expired' : 'Available'}</Tag></Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>  {email.length ? email : 'Not used'}</Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
                    {/* <Text>Block</Text> */}
                    <Button
                        _hover={{
                            bgColor: "rgb(220, 20, 60)",
                            color: "white",
                        }}
                        color={"rgb(220, 20, 60)"}
                        bgColor={"white"}
                        w={"75%"}
                        h={"2.35rem"}
                        p={0}
                        fontSize={"0.97rem"}
                        border={"1px solid rgb(220, 20, 60) !important"}
                        onClick={() => OpenBlockModal(id, "delete")}
                    >
                        Delete
                    </Button>
                </Td>
            </Tr>
        </>
    );
};

export default CouponCard;
