import React, { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BroadcastChannel } from 'broadcast-channel';

import {
  CreateUser,
  ForgotApi,
  GetAllServices,
  getDiscountServicesUser,
  GoogleApi,
  LoginApi,
  LogoutApi,
  SignupApi,
} from "../utils/api";
import axios from "axios";

export const AppContext = createContext();
function generateUniqueIdentifier() {
  // Generate a random string
  return Math.random().toString(36).substr(2, 9);
}
export default function AppContextProvider({ children }) {
  const [isDark, setIsDark] = useState(false);
  const Token = JSON.parse(localStorage.getItem("token")) || null;
  const AdminToken = JSON.parse(localStorage.getItem("Token")) || null;
  const [isAuth, setIsAuth] = useState(Token ? true : false);
  const [balance, setBalance] = useState(0);
  const [server1Discount, setServer1Discount] = useState(0);
  const [server2Discount, setServer2Discount] = useState(0);
  const [server3Discount, setServer3Discount] = useState(0);
  const [isAdmin, setIsAdmin] = useState(AdminToken ? true : false);
  const [alreadyOpenInTab, setAlreadyOpenInTab] = useState(false);
  const toggleDark = () => {
    setIsDark(!isDark);
  };
  // const dispatch = useDispatch();
  // #################### reducer logic ####################
  const toast = useToast();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, SetLoading] = useState(false);
  const [error, setError] = useState(false);
  const [servicesData1, setServicesData1] = useState([]);
  const [servicesData2, setServicesData2] = useState([]);
  const [servicesData3, setServicesData3] = useState([]);
  const [defaultServer, setDefaultServer] = useState('server1');
  const [userEmail, setUserEmail] = useState('');
  const [selectService, setServiceService] = useState({
    service: "",
    code: "",
    server: ""
  });
  useEffect(() => {
    const channel = new BroadcastChannel('unique_channel_name');
    const tabId = generateUniqueIdentifier();

    channel.postMessage({ action: 'tabOpened', tabId });
    setAlreadyOpenInTab(false);
    channel.onmessage = (event) => {
      if (event.tabId !== tabId) {
        setAlreadyOpenInTab(true);
        window.open('', '_self').close()
      }
    };

    return () => {
      // setAlreadyOpenInTab(false);
      // Clean up event listeners
      channel.close();
    };

  }, []);
  const handleLogout = (err) => {
    if (err.response?.data?.message === 'Invalid signature' || err.response?.data?.message === 'Not Authorised' || err.response?.data?.message == 'user already logged in another device') {
      if (isAdmin) {
        localStorage.removeItem('Token');
        setIsAdmin(false);
      } else if (isAuth) {
        localStorage.removeItem('token');
        setIsAuth(false);
      }
    } else {
      toast({
        title: err.response?.data?.message
          ? err.response?.data.message
          : err.message,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  }
  //single device feature
  useEffect(() => {
    let checkSingleDevice = JSON.parse(localStorage.getItem('singleDevice')) || null;
    if (!checkSingleDevice) {
      if (Token) {
        LogoutApi(Token).then((res) => {
          localStorage.removeItem("token");
          localStorage.setItem('singleDevice', true);
          setIsAuth(false);
          toast({
            status: "success",
            title: "Successfully logout",
            isClosable: true,
            duration: 2000,
          });
        }).catch((err) => {
          toast({
            title: err.response?.data?.message || 'Error contact admin',
            status: "error",
            isClosable: false,
          });
        })
      }
    }
  }, [])
  useEffect(() => {
    SetLoading(true);
  }, []);

  useEffect(() => {
    if (isAuth) {
      GetAllServices(Token).then((res) => {
        if (res.data.services.length) {
          let server1 = [];
          let server2 = [];
          let server3 = [];
          res.data.services?.map((el) => {
            if (el.server == 'server3') {
              server1.push(el);
            } else if (el.server == 'server2') {
              server2.push(el);
            } else if (el.server == 'server1') {
              server3.push(el);
            }
          })
          setServicesData1(server1);
          setServicesData2(server2);
          setServicesData3(server3)
        }
      });
      getDiscountServicesUser(Token).then((res) => {
        setServer1Discount(res.data.server2Discount);
        setServer2Discount(res.data.server3Discount);
        setServer3Discount(res.data.server1Discount);
      }).catch((err) => {
        handleLogout(err);
        console.log(err, 'in fetching discount');
      })
    }
  }, [isAuth]);

  // ############### Signup User ########################

  const signupUser = async (email, password) => {
        if (isAdmin) {
          return CreateUser(AdminToken, { email, password })
            .then((myres) => {
              console.log(myres, "checking new response");
              toast({
                title: "User Create.",
                description: "Admin created new user.",
                status: "success",
                duration: 3000,
                isClosable: true,
                position: "top",
              });
              return myres;
            })
            .catch((err) => {
              toast({
                title: "Error",
                description: err.response?.data?.message
                  ? err.response?.data.message
                  : err.message,
                status: "error",
                duration: 2500,
                isClosable: true,
                position: "top",
              });
            });
        } else {
          return SignupApi({ email, password })
            .then((myres) => {
              toast({
                title: "Account created.",
                description: "We've created your account for you.",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top",
              });
              setTimeout(() => {
                navigate("/login");
              }, 1000);
              return myres;
            })
            .catch((err) => {
              toast({
                title: "Error",
                description: err.response?.data?.message
                  ? err.response?.data.message
                  : err.message,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
              });
            });
        }
  };

  // ############### Login User ########################
  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }   
  const loginUser = (email, password) => {
    SetLoading(true);
        let session = Date.now();
        session+=getRandomNumber(1,5);
        session+=getRandomNumber(6,49);
        session+=getRandomNumber(50,10)
        localStorage.setItem('session', session);
        LoginApi({
          email,
          password,
          flag: true,
          session
        })
          .then((myres) => {
            toast({
              title: "Login Successful",
              description: "You have Successfully Login.",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            console.log(myres.data, "my response");
            if (myres.data.token) {
              setIsAuth(true);
              localStorage.setItem("token", JSON.stringify(myres.data.token));
              localStorage.setItem('singleDevice', true);
            }
            setTimeout(() => {
              // navigate("/");
            }, 1000);
          })
          .catch((err) => {
            toast({
              title: "Error",
              description: err.response?.data?.message
                ? err.response?.data.message
                : err.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          });
  };

  // ############### Logout User ########################

  const logoutUser = () => {
   
  };

  // ############### Forgot Password ########################

  const forgotPassword = (email) => {
        ForgotApi({ email })
          .then((myres) => {
            console.log(myres);
            toast({
              title: "Forgot Password",
              description: `Reset link has been send to your resigtered email id ${email}`,
              status: "success",
              duration: 6000,
              isClosable: true,
              position: "top",
            });
            setTimeout(() => {
              navigate("/login");
            }, 1800);
          })
          .catch((err) => {
            toast({
              title: "Error",
              description: err.response?.data?.message
                ? err.response?.data.message
                : err.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top",
            });
          });
  };

  // ############### Signin with google ########################

  const signInWithGoogle =async () => {
          let session = Date.now();
          localStorage.setItem('session', session);
          const response = await axios.get(`${process.env.REACT_APP_API}`)
          if(response.status==200){
            window.location.href = `${process.env.REACT_APP_API}auth/google?session=${session}`;
          };    
          // GoogleApi({ email: email, session })
          //   .then((myres) => {
          //     localStorage.setItem("token", JSON.stringify(myres.data.token));
          //     toast({
          //       title: "Login Successfull",
          //       description: "You have Successfully Login.",
          //       status: "success",
          //       duration: 5000,
          //       isClosable: true,
          //       position: "top",
          //     });
          //     setIsAuth(true);
          //     localStorage.setItem('singleDevice', true);
          //     setTimeout(() => {
          //       // navigate("/");
          //     }, 1000);
          //   })
          //   .catch((err) => {
          //     toast({
          //       title: "Error",
          //       description: err.response?.data?.message
          //         ? err.response?.data.message
          //         : err.message,
          //       status: "error",
          //       duration: 3000,
          //       isClosable: true,
          //       position: "top",
          //     });
          //   });
  }

  const contextValue = {
    user,
    loading,
    error,
    signupUser,
    loginUser,
    logoutUser,
    forgotPassword,
    signInWithGoogle,
    isDark,
    toggleDark,
    Token,
    isAuth,
    setIsAuth,
    isAdmin,
    setIsAdmin,
    AdminToken,
    servicesData1,
    selectService,
    setServiceService,
    balance,
    setBalance,
    servicesData2,
    servicesData3,
    server1Discount,
    server2Discount, server3Discount,
    handleLogout,
    setDefaultServer,
    defaultServer,
    setUserEmail,
    userEmail,
    alreadyOpenInTab
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};