import { Button, HStack, Text, IconButton, Box,Image } from "@chakra-ui/react";
import React, { useContext } from "react";
import { RoughNotation } from "react-rough-notation";
import { BiUserCircle } from "react-icons/bi";
import { FaSun, FaMoon, FaBars } from "react-icons/fa";
import { AppContext } from "../context/AppContext";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/logo2.jpg'
const Navbar = ({ onOpen }) => {
  const { toggleDark, isDark, balance } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <HStack
      flexDirection={["row-reverse", "row-reverse", "row", "row"]}
      bgColor={isDark ? "#0F1624" : "#EDF2F8"}
      p={["1.4rem 1.7rem", "1.4rem 1.8rem", "1.5rem 2rem", "1.5rem 2rem"]}
      w={"100%"}
      border={"1px solid"}
      justify={"space-between"}
    >
      <HStack gap={"3rem"}>
        {/* <Text fontWeight={700} fontSize={"2rem"}>
          <RoughNotation
            strokeWidth={2}
            color={"rgb(220, 20, 60)"}
            type="circle"
            show={true}
          >
            OTP
          </RoughNotation>
        </Text> */}
        <Image rounded={'50%'} src={Logo} w={'4rem'} h={'4rem'} />
        <Button
          p={"1.3rem 1rem"}
          display={["none", "none", "inline-flex", "inline-flex"]}
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          fontSize={"1.2rem"}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          onClick={() => navigate("/payment")}
          border={"2.4px solid rgb(220, 20, 60) !important"}
        >
          Add Balance
        </Button>
      </HStack>
      <HStack
        flexDirection={["row-reverse", "row-reverse", "row", "row"]}
        gap={["0.4rem", "0.5rem", "0.8rem", "0.8rem"]}
      >
        <Text
          display={["none", "none", "flex", "flex"]}
          alignItems={"center"}
          fontSize={"1.2rem"}
          fontWeight={"700"}
          gap={"0.3rem"}
        >
          Welcome, <Link to={'/faq'} style={{color:'rgb(220, 20, 60)'}}>FAQ</Link>
        </Text>
        <Text
          display={["flex", "flex", "none", "none"]}
          alignItems={"center"}
          fontSize={"1.2rem"}
          fontWeight={"700"}
          gap={"0.3rem"}
        >
          {/* <BiUserCircle style={{ fontSize: "1.5rem" }} /> */}
          <Link to={'/faq'} style={{color:'rgb(220, 20, 60)'}}>FAQ</Link>
        </Text>
        <Text fontSize={"1.2rem"}>₹{Number(balance).toFixed(1)}</Text>
        {/* <IconButton
          ml="10px"
          isRound
          style={
            isDark
              ? {
                  background: "#0f1624",
                  color: "white ",
                }
              : {
                  background: "#edf2f8",
                }
          }
          icon={isDark ? <FaSun /> : <FaMoon />}
          onClick={toggleDark}
        /> */}
        <Box
          onClick={onOpen}
          cursor={"pointer"}
          display={["block", "block", "block", "none"]}
          fontSize={"1.8rem"}
        >
          <FaBars />
        </Box>
      </HStack>
    </HStack>
  );
};

export default Navbar;
