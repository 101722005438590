import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  ModalBody,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  VStack,
  useDisclosure,
  Input,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,
  Image,
  Textarea,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { BiEdit } from "react-icons/bi";
import { MdFileDownloadDone } from "react-icons/md";
import Select from "react-select";
import {
  AddCurrency,
  AddService,
  EditCurrency,
  EditUpi,
  GetAdminAllCurrency,
  GetAdminSingleService,
  GetUPI,
} from "../../../utils/api";
import Empty from "../../../components/Empty";
import ServicesCard from "../components/ServicesCard";
import CurrencyCard from "../components/CurrencyCard";
const Currency = () => {
  const addCurrencyPrice = useRef({});
  const addCurrencyName = useRef({});
  const addCurrencyColor = useRef();
  const upiTokenRef = useRef({});
  const upiImgRef = useRef({});
  const upiIdRef = useRef({});
  const { AdminToken } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editModal, setEditModal] = React.useState(false);
  const [upiModal, setUpiModal] = useState(false);
  const toast = useToast();
  const [currencyId, setCurrencyId] = useState("");
  const [displayEditUpiImg, setDisplayEditUpiImgId] = useState("");
  const [displayEditImg, setDisplayEditImg] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleService, setSingleService] = useState({});
  const [upiData,setUpiData] = useState({});
  const [tokenEdit, setTokenEdit] = useState(false);
  const handleAddCurrency = (event) => {
    event.preventDefault();
    let currencyData = {};
    currencyData.currencyPrice = addCurrencyPrice.current.value;
    currencyData.currencyName = addCurrencyName.current.value;
    if (!currencyData.currencyPrice || !currencyData.currencyName) {
      toast({
        title: `Please Fill All Input Boxes`,
        status: "warning",
        isClosable: true,
        duration: 3000,
      });
    } else {
      AddCurrency(currencyData, AdminToken)
        .then((res) => {
          toast({
            title: `Currency Added Successfully`,
            status: "success",
            isClosable: true,
            duration: 3000,
          });
          setData([...data, res.data.newCurrency]);
          onClose();
        })
        .catch((err) => {
          toast({
            title: err.response?.data?.message
              ? err.response?.data.message
              : err.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        });
    }
  };
  const handleEditCurrency = (event) => {
    event.preventDefault();
    let Data = {
      currencyName: addCurrencyName.current.value,
      currencyPrice: addCurrencyPrice.current.value,
      color:addCurrencyColor.current.value,
      imgUrl:displayEditImg
    };
    console.log(displayEditImg,'checking Img for edit');
    EditCurrency(currencyId, Data, AdminToken)
      .then((res) => {
        console.log(res.data);
        toast({
          title: "currency updated successfully!!!",
          isClosable: true,
          status: "success",
        });
        CloseBlockModal();
        let currentData = data;
        currentData = currentData.map((el) => {
          if (el._id == currencyId) {
            el = { ...el, ...Data };
          }
          return el;
        });
        setData([...currentData]);
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: err.response?.data?.message
            ? err.response?.data.message
            : err.message,
          isClosable: true,
          status: "error",
        });
      });
  };
  const OpenBlockModal = (currencyId, el) => {
    setCurrencyId(currencyId);
    setSingleService(el);
    setEditModal(true);
    setDisplayEditImg(el.imgUrl);
  };
  const CloseBlockModal = () => {
    setEditModal(false);
  };
  useEffect(() => {
    setLoading(false);
    GetUPI(AdminToken)
      .then((res) => {
        console.log(res.data.data,'checking');
        res = res.data; 
        setDisplayEditUpiImgId(res.data.imgURL);
        setUpiData({Token:res.data.Token,upiId:res.data.upiId,imgURL:res.data.imgURL});
      })
      .catch((err) => {
        console.log("error in upi token", err);
      });
    GetAdminAllCurrency(AdminToken)
      .then((res) => {
        console.log(res.data, "checking my response currencies");
        setLoading(true);
        setData(res.data.currencies);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error saying");
      });
  }, []);
  const handleUpiUpdate = () => {
    if(upiTokenRef.current.value == ""||upiImgRef.current.value == ""||upiIdRef.current.value == ""){
      toast({
        status:'warning',
        title:"Please fill all the inputs",
        isClosable:true
      })
      return 
    };
    
      EditUpi(AdminToken, { Token: upiTokenRef.current.value,upiId:upiIdRef.current.value,imgURL:upiImgRef.current.value })
        .then((res) => {
          toast({
            title: "Upi Token Updated Successfully",
            status: "success",
            isClosable: true,
          });
          setUpiModal(false);
          setUpiData({Token:upiTokenRef.current.value,upiId:upiIdRef.current.value,imgURL:upiImgRef.current.value});
        })
        .catch((err) => {
          toast({
            status: "error",
            title: err.response?.data?.message
              ? err.response?.data.message
              : err.message,
            isClosable: true,
          });
        });
    setTokenEdit(!tokenEdit);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      <HStack>

        <Button
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          onClick={onOpen}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          border={"2.4px solid rgb(220, 20, 60) !important"}
        >
          Add Currency
        </Button>
        <Button
          _hover={{
            bgColor: "white",
            color: "rgb(220, 20, 60)",
          }}
          onClick={() =>{
             setUpiModal(true);
            //  upiTokenRef.current.value = upiData?.Token
            //  upiIdRef.current.value = upiData?.upiId
            console.log(upiData,'checking');
            //  upiImgRef.current.value= upiData?.imgURL
            }}
          color={"white"}
          bgColor={"rgb(220, 20, 60)"}
          border={"2.4px solid rgb(220, 20, 60) !important"}
        >
          Edit UPI
        </Button>
      </HStack>

      <TableContainer
        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        w={"100%"}
      >
        <Table
          variant="simple"
          colorScheme="facebook"
          bgColor="white"
          size={["md", "lg", "lg"]}
          overflowX={"auto"}
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
        >
          <Thead background={"rgb(229 60 94)"} color={"white !important"}>
            <Tr>
              <Th color={"white"}>UPI Id</Th>
              <Th color={"white"}>Upi Token</Th>
              <Th color={"white"}>Image</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td
                pt={"0.7rem !important"}
                pb={"0.7rem !important"}
                borderBottom={"1px solid rgb(229 60 94)"}
              > 
                <Textarea size={'sm'} w={'60%'} value={upiData?.upiId} disabled={true} h={'1rem'} />
              </Td>
              <Td
                pt={"0.7rem !important"}
                pb={"0.7rem !important"}
                borderBottom={"1px solid rgb(229 60 94)"}
              >
                <Textarea disabled={true} value={upiData?.Token} size={'sm'} w={'60%'} />
              </Td>
              <Td
                pt={"0.7rem !important"}
                pb={"0.7rem !important"}
                borderBottom={"1px solid rgb(229 60 94)"}
              >
                <Image src={upiData?.imgURL} w={"7rem"} h={"7rem"} alt={'bharat pay qr'} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data?.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>Currency</Th>
                <Th color={"white"}>Price</Th>
                <Th color={"white"}>color</Th>
                <Th color={"white"}>Image</Th>
                <Th color={"white"}>Edit</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <CurrencyCard
                    OpenBlockModal={OpenBlockModal}
                    CloseBlockModal={CloseBlockModal}
                    key={el._id}
                    id={el._id}
                    currencyName={el.currencyName}
                    currencyPrice={el.currencyPrice}
                    currencyColor={el.color||'red'}
                    img={el?.imgUrl}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {!data?.length && loading ? <Empty title={"No user found!!!"} /> : <></>}
      {/* Edit UPI Model */}
      <Modal isOpen={upiModal} onClose={() => setUpiModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
            pt={5}
          >
            <form style={{ width: "76.5%" }} onSubmit={handleSubmit}>
              <VStack p={"1.5rem 0.8rem"} align="center" >
                <Text w={'100%'}>Upi id:-</Text>
                <Input
                  placeholder="UPI ID"
                  defaultValue={upiData.upiId}
                  ref={upiIdRef}
                  />
                  <Text w={'100%'}>Upi Token:-</Text>
                <Input
                  placeholder="Upi Token"
                  defaultValue={upiData.Token}
                  ref={upiTokenRef}
                />
               <VStack
                  justify={"center"}
                  width={"7rem"}
                  border={"1px solid"}
                  height={"7rem"}
                >
                  {displayEditUpiImg?.length ? (
                    <Image src={displayEditUpiImg} w={"100%"} h={"100%"} />
                  ) : (
                    <Text>Image</Text>
                  )}
                </VStack>
                <Input
                  type={"text"}
                  ref={upiImgRef}
                  placeholder="Img url"
                  defaultValue={upiData.imgURL}
                  onChange={(e) => setDisplayEditUpiImgId(e.target.value)}
                />
                <Button
                  _hover={{
                    color: "rgb(218 52 85)",
                    background: "white",
                  }}
                  type="submit"
                  mt={"2rem !important"}
                  bg="rgb(218 52 85)"
                  color={"white"}
                  border="1.5px solid rgb(218 52 85) !important"
                  width="full"
                  outline={"none"}
                  onClick={handleUpiUpdate}
                >
                  Edit UPI
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Edit User Model */}
      <Modal isOpen={editModal} onClose={CloseBlockModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
            pt={5}
          >
             <form style={{ width: "76.5%" }} onSubmit={handleSubmit}>
              <VStack align="center" p={"1.5rem 0.8rem"}>
                <Input
                  placeholder="Currency Name"
                  defaultValue={singleService.currencyName}
                  ref={addCurrencyName}
                />
                <Input
                  type={"number"}
                  placeholder="Currency Price"
                  defaultValue={singleService.currencyPrice}
                  ref={addCurrencyPrice}
                />
                <VStack
                  justify={"center"}
                  width={"3rem"}
                  border={"1px solid"}
                  height={"3rem"}
                >
                  {displayEditImg?.length ? (
                    <Image src={displayEditImg} w={"100%"} h={"100%"} />
                  ) : (
                    <Text>Image</Text>
                  )}
                </VStack>
                <Input
                  type={"text"}
                  placeholder="Img url"
                  defaultValue={singleService.imgUrl}
                  onChange={(e) => setDisplayEditImg(e.target.value)}
                />
                <HStack align={"flex-end"} w={"100%"}>
                  <Text fontWeight={600}>Choose color</Text>{" "}
                  <Input
                    type={"color"}
                    defaultValue={singleService.color}
                    ref={addCurrencyColor}
                    w={"30%"}
                  />
                </HStack>
                <Button
                  _hover={{
                    color: "rgb(218 52 85)",
                    background: "white",
                  }}
                  type="submit"
                  mt={"2rem !important"}
                  bg="rgb(218 52 85)"
                  color={"white"}
                  border="1.5px solid rgb(218 52 85) !important"
                  width="full"
                  outline={"none"}
                  onClick={handleEditCurrency}
                >
                  Edit Currency
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Add User Modal  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
            pt={5}
          >
            <form style={{ width: "76.5%" }} onSubmit={handleSubmit}>
              <VStack p={"1.5rem 0.8rem"} align="flex-start">
                <Input placeholder="Currency Name" ref={addCurrencyName} />
                <Input
                  type={"number"}
                  placeholder="Currency Price"
                  ref={addCurrencyPrice}
                />
                {false && (
                  <Image
                    src={""}
                    w={"100%"}
                    h={"1.8rem"}
                    ml={"auto"}
                    display={"block"}
                    mr={"auto"}
                    alt={"currency logo"}
                  />
                )}
                <Input
                  type={"text"}
                  placeholder="Enter Img url"
                  ref={addCurrencyPrice}
                />
                <HStack align={"flex-end"} w={"100%"}>
                  <Text fontWeight={600}>Choose color</Text>{" "}
                  <Input
                    type={"color"}
                    defaultValue={singleService.currencyPrice}
                    ref={addCurrencyPrice}
                    w={"30%"}
                  />
                </HStack>
                <Button
                  _hover={{
                    color: "rgb(218 52 85)",
                    background: "white",
                  }}
                  type="submit"
                  mt={"2rem !important"}
                  bg="rgb(218 52 85)"
                  color={"white"}
                  border="1.5px solid rgb(218 52 85) !important"
                  width="full"
                  outline={"none"}
                  onClick={handleAddCurrency}
                >
                  Add new currency
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Currency;
