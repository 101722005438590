import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaChartLine, FaUserCircle } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineHistory } from "react-icons/ai";
import { BsFillCreditCardFill, BsLightningChargeFill, BsCurrencyBitcoin } from "react-icons/bs";
import { MdBlock, MdOutlineLocalOffer } from "react-icons/md";
import { SlGraph } from "react-icons/sl";
import { AppContext } from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";

const ButtonsList = [
  {
    title: "Dashboard",
    icon: <FaChartLine style={{ fontSize: "1.5rem" }} />,
    link: "/admin/dashboard",
  },
  {
    title: "Users",
    icon: <FaUserCircle style={{ fontSize: "1.5rem" }} />,
    link: "/admin/users",
  },
  {
    title: "Recharge",
    icon: <BsLightningChargeFill style={{ fontSize: "1.5rem" }} />,
    link: "/admin/recharge",

  },
  {
    title: "Daily Stats",
    icon: <SlGraph style={{ fontSize: "1.5rem" }} />,
    link: "/admin/daily",
  },
  {
    title: "Currency",
    icon: <BsCurrencyBitcoin style={{ fontSize: "1.5rem" }} />,
    link: "/admin/currency",
  },
  {
    title: "Services",
    icon: <BsFillCreditCardFill style={{ fontSize: "1.5rem" }} />,
    link: "/admin/services",
  },
  {
    title: "Transactions",
    icon: <AiOutlineHistory style={{ fontSize: "1.5rem" }} />,
    link: "/admin/transaction",
  },
  {
    title: "Coupon",
    icon: <MdOutlineLocalOffer style={{ fontSize: "1.5rem" }} />,
    link: "/admin/coupon",
  },
  {
    title: "Blocked Users",
    icon: <MdBlock style={{ fontSize: "1.7rem" }} />,
    link: "/admin/blockedusers",
  },
  { title: "Logout", icon: <FiLogOut style={{ fontSize: "1.5rem" }} /> },
];
const AdminLeftSideBar = () => {
  const [active, setActive] = useState("");
  const { isDark, setIsAdmin } = useContext(AppContext);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  useEffect(() => {

  }, [])
  const handleLogout = () => {
    onClose();
    localStorage.removeItem("Token");
    setIsAdmin(false);
    toast({
      status: "success",
      title: "Successfully logout",
      isClosable: true,
      duration: 2000,
    });
  };
  return (
    <VStack top={"1rem"} w={"21%"} position={"sticky"}>
      <VStack w={"89.5%"} gap={"1rem"} pt={"1rem"} align={"center"}>
        {ButtonsList.map((el) => (
          <Button
            key={el.title}
            outline={"none !important"}
            onClick={() => {
              setActive(el.title);
              if ("Logout" == el.title) {
                onOpen();
              } else {
                navigate(el.link);
              }
            }}
            _hover={{
              background: "#fafafa !important",
              color: "rgb(220, 20, 60) !important",
            }}
            style={
              active === el.title
                ? {
                  background: "white",
                  color: "rgb(220, 20, 60)",
                }
                : {
                  background: "transparent",
                  color: isDark ? "white" : "black",
                }
            }
            gap={"0.6rem"}
            fontWeight={400}
            // rounded={"6rem"}
            border={"none !important"}
            fontSize={"1.15rem"}
            p={"1.4rem 0.6rem"}
            w={"95%"}
            justifyContent={"start"}
            leftIcon={el.icon}
            colorScheme="white"
            color={"black"}
          >
            {el.title}
          </Button>
        ))}
      </VStack>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
          >
            <Button
              onClick={handleLogout}
              _hover={{
                bgColor: "white",
                color: "rgb(220, 20, 60)",
              }}
              color={"white"}
              bgColor={"rgb(220, 20, 60)"}
              border={"2.4px solid rgb(220, 20, 60) !important"}
            >
              Logout
            </Button>
            <Button
              onClick={onClose}
              color={"rgb(220, 20, 60)"}
              bg={"white"}
              border={"2.4px solid rgb(220, 20, 60) !important"}
              _hover={{
                bgColor: "rgb(220, 20, 60)",
                color: "white",
              }}
            >
              Cancel
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default AdminLeftSideBar;
