import React, { useContext } from 'react'
import Lottie from "lottie-react";
import animationData from "../../assets/notallowed.json";
import { Text, VStack, useMediaQuery } from '@chakra-ui/react';
import { AppContext } from '../../context/AppContext';

const NotAllowed = ({ children }) => {
    const { alreadyOpenInTab } = useContext(AppContext);
    const [isLargerThan450] = useMediaQuery("(min-width: 450px)");
    if (!alreadyOpenInTab) {
        return children;
    }
    return (
        <VStack w={"100%"}>
            <Lottie style={isLargerThan450 ? { width: 420, height: 420 } : { width: 250, height: 250 }} animationData={animationData} loop={true} />
            <Text color={"rgb(220, 20, 60)"} textAlign={'center'} fontSize={['1.37rem', '1.7rem', '2rem', "2rem"]} fontWeight={700}>
                You've already open in another tab It'll only works in single tab use last one
            </Text>
        </VStack>)
}

export default NotAllowed