import { Grid, GridItem, HStack, VStack, Text, Box } from "@chakra-ui/react";
import React, { useContext,useState,useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import AdminCard from "./AdminCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import axios from "axios";
import { getStartAndEndDateOfWeek } from "../../../utils/utils";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
const getAdminMonthlyStats = () => {
  return axios.get(`${process.env.REACT_APP_API}stats/monthlyStat`)
}
const getPieData = () => {
  return axios.get(`${process.env.REACT_APP_API}stats/services/top`)
}
const DashboardCard = () => {
  const { isDark } = useContext(AppContext);
  let [weeklydata, setweeklyData] = useState({});
  let [dailydata, setdailyData] = useState({});
  let [pieLabel, setPieLabel] = useState(["Red", "Blue", "Yellow", "Green", "Purple"]);
  let [pieData, setPieData] = useState([12, 19, 3, 5, 2]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Daily Users Logined",
      },
    },
  };
  let linedata = {
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    datasets: [
      {
        label: "Team A",
        data: [65, 59, 80, 81, 56, 55],
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
      {
        label: "Team B",
        data: [40, 50, 75, 70, 65, 80],
        fill: false,
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.1,
      },
      {
        label: "Team C",
        data: [35, 45, 60, 70, 75, 90],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
      },
    ],
  };
  let piedata = {
    labels: pieLabel,
    datasets: [
      {
        label: "Service Used",
        data: pieData,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };
  useEffect(() => {
    getAdminMonthlyStats().then((res) => {
      let monthData = res.data.monthStats[0];
      let { startOfWeek } = getStartAndEndDateOfWeek();
      for (let i = 0; i < monthData.weeklyStats.length; i++) {
        if (monthData.weeklyStats[i].week == startOfWeek) {
          setweeklyData(monthData.weeklyStats[i]);
          break;
        }
      }
      let currentDay = new Date().setHours(0, 0, 0, 0);
      currentDay = new Date(currentDay).toISOString();
      for (let i = 0; i < monthData.dailyStats.length; i++) {
        if (monthData.dailyStats[i].date == currentDay) {
          setdailyData(monthData.dailyStats[i]);
          break;
        }
      }
    }).catch((e) => {
      console.log(e);
    })
    getPieData().then(res => {
      let labels = [];
      let dataset = [];
      for (let i = 0; i < res.data.result.length; i++) {
        labels.push(res.data.result[i].serviceName);
        dataset.push(res.data.result[i].count);
      }
      setPieLabel(labels);
      setPieData(dataset);
    }).catch(e => {
      ; console.log(e)
    })
  }, []);
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"center"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      boxSizing="border-box"
      w={"79%"}
      p={"1rem"}
    >
      <Text fontSize={"26px"} fontWeight={700} align={"start"}>
        Hi, Welcome Back
      </Text>
      <HStack
        w={"100%"}
        boxSizing="border-box"
        p={"1rem 2rem"}
        gap={"1rem"}
        align={"center"}
        justify={"center"}
      >
        <AdminCard
          color={"red"}
          data={{ count: weeklydata.userCount||0, title: "Weekly New Users Count" }}
          logo={
            "https://img.freepik.com/free-vector/decorative-mandala-design-with-pastel-colours_1048-5936.jpg?w=740&t=st=1685770416~exp=1685771016~hmac=54f3ecaf06e21494394f814e9f5fa0e258e363cd554456031998a589b5081c9e"
          }
        />

        <AdminCard
          color={"green"}
          data={{ count: weeklydata.newMobileCount||0, title: "Weekly New Mobile Generated" }}
          logo={
            "https://img.freepik.com/free-vector/decorative-mandala-design-with-pastel-colours_1048-5936.jpg?w=740&t=st=1685770416~exp=1685771016~hmac=54f3ecaf06e21494394f814e9f5fa0e258e363cd554456031998a589b5081c9e"
          }
        />

        <AdminCard
          color={"blue"}
          data={{ count: dailydata.newMobileCount||0, title: "Daily Mobile No. Generated" }}
          logo={
            "https://img.freepik.com/free-vector/decorative-mandala-design-with-pastel-colours_1048-5936.jpg?w=740&t=st=1685770416~exp=1685771016~hmac=54f3ecaf06e21494394f814e9f5fa0e258e363cd554456031998a589b5081c9e"
          }
        />

        <AdminCard
          color={"yellow"}
          data={{ count: dailydata.otpCount||0, title: "Daily OTP No. Generated" }}
          logo={
            "https://img.freepik.com/free-vector/decorative-mandala-design-with-pastel-colours_1048-5936.jpg?w=740&t=st=1685770416~exp=1685771016~hmac=54f3ecaf06e21494394f814e9f5fa0e258e363cd554456031998a589b5081c9e"
          }
        />
      </HStack>
      <HStack p={"1.5rem"} w={"90%"} align={"start"} justify={"space-between"}>
        <Box
          p={"1rem"}
          borderRadius={"5px"}
          width={"40vw"}
          boxShadow={
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
          }
        >
          <Line options={options} data={linedata} />
        </Box>
        <Box
          p={"1rem"}
          borderRadius={"5px"}
          maxWidth={"20vw"}
          boxShadow={
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
          }
        >
          <Pie data={piedata} />
        </Box>
      </HStack>
    </VStack>
  );
};

export default DashboardCard;