import React, { useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const OAuth = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {setIsAuth} = useContext(AppContext)
    useEffect(() => {
        let token = searchParams.get("token");
        if (token) {
                setIsAuth(true);
                localStorage.setItem("token", JSON.stringify(token));
                localStorage.setItem('singleDevice', true);
                navigate("/");
        } else {
            navigate("/");
        }
    }, [])
    return (
        <></>
    );
}

export default OAuth