import {
    Box,
    Button,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Td,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import React from "react";

const CurrencyCard = ({ id, currencyName, currencyPrice, OpenBlockModal,img,currencyColor }) => {
    // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
    return (
        <>
            <Tr>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{currencyName}</Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{currencyPrice}</Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}><Box bgColor={currencyColor} w={'1rem'} h={'1rem'} borderRadius={'50%'}></Box></Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}><Image src={img} w={'1.5rem'} h={'1.5rem'} /></Td>
                <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}><Button
                    _hover={{
                        bgColor: "rgb(220, 20, 60)",
                        color: "white",
                    }}
                    color={"rgb(220, 20, 60)"}
                    bgColor={"white"}
                    w={"40%"}
                    h={"2rem"}
                    p={0}
                    fontSize={"0.97rem"}
                    border={"1px solid rgb(220, 20, 60) !important"}
                    onClick={() => OpenBlockModal(id, { currencyName, currencyPrice,imgUrl:img,color:currencyColor })}
                >
                    Edit
                </Button></Td>
            </Tr>
        </>
    );
};

export default CurrencyCard;
