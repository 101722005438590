import {
    VStack,
    Button,
    HStack,
    useToast,
    Heading,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Select,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RoughNotation } from "react-rough-notation";
import { FiLogOut } from "react-icons/fi";
import { Helmet } from "react-helmet";
const Referral = () => {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Referral | Techy India</title>
            </Helmet>
            <VStack
                gap={"1rem"}
                bg={"white"}
                align={"start"}
                justify={"start"}
                height={"100%"}
                minH={"100vh"}
                w={["97%", "100%", "100%", "79%"]}
                p={"1.5rem"}
            >
                <Heading>
                    Refer
                    <RoughNotation
                        strokeWidth={2}
                        color={"rgb(220, 20, 60)"}
                        type="underline"
                        show={true}
                    >
                        {` And `}
                    </RoughNotation>
                    Earn
                </Heading>
                <VStack gap={'2rem'} w={['97%', '100%', '100%', '100%']} align={'center'} justify={'start'}>
                    <Heading>!Coming soon</Heading>
                </VStack>
            </VStack>
        </>
    );
};

export default Referral;
