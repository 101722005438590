import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,
  Heading,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Empty from "../../components/Empty";
import OtpHistoryCard from "../../components/OtpHistoryCard";
import { RoughNotation } from "react-rough-notation";
import { GetOtpHistoryAPI } from "../../utils/api";
import { Helmet } from "react-helmet";
import Select from "react-select";
const DurationData = [
  {
    label: 'All time',
    value: 'all'
  },
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Last 7 days',
    value: 'lastWeek'
  },
  {
    label: 'Last 30 days',
    value: 'lastMonth'
  },
];
const OtpHistory = () => {
  const { Token, signupUser } = useContext(AppContext);
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [durationFilter, setDurationFilter] = useState('all');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelBtnRequestInProgress, setCancelBtnRequestInProgress] = useState(false);
  useEffect(() => {
    setLoading(false);
    GetOtpHistoryAPI(Token, pageNo, limitNo, durationFilter)
      .then((res) => {
        setLoading(true);
        console.log(res.data);
        setData(res.data.data);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo, pageNo, durationFilter]);
  const handleOTPCancel = (cancelId) => {
    let currentData = data;
    currentData = currentData.map((el) => {
      if (el._id == cancelId) {
        el.status = 'cancelled';
      };
      return el;
    });
    setData([...currentData]);
  };
  const handleOTPMessage = (mobileId, otpMessage) => {
    let currentData = data;
    currentData = currentData.map((el) => {
      if (el._id == mobileId) {
        el.status = 'success';
        el.otpIds = [...el.otpIds, { otpMessage }];
      };
      return el;
    });
    setData([...currentData]);
  };
  const handleChange = (event) => {
    console.log(event.value)
    setDurationFilter(event.value);
  };
  return (
    <>
      <Helmet>
        <title>Otp History | Techy India</title>
      </Helmet>
      <VStack
        gap={"1rem"}
        bg={"white"}
        align={"start"}
        justify={"start"}
        height={"100%"}
        minH={"100vh"}
        w={["97%", "100%", "100%", "79%"]}
        p={"1.5rem"}
      >
        <Heading>
          Otp
          <RoughNotation
            strokeWidth={2}
            color={"rgb(220, 20, 60)"}
            type="underline"
            show={true}
          >
            {` History`}
          </RoughNotation>
        </Heading>
        <HStack w={'100%'}>
          <HStack w={['85%', '80%', '45%', '38%']}>
            <Select
              onChange={handleChange}
              required={true}
              defaultValue={DurationData[0]}
              options={DurationData}
              style={{
                width: "100%",
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(255 227 233 / 63%)",
                  primary: "rgb(231 70 102)",
                },
              })}
            />
          </HStack>
          {/* <HStack w={'38%'}>
            <Select
              onChange={handleChange}
              required={true}
              options={DurationData}
              style={{
                width: "100%",
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "rgb(255 227 233 / 63%)",
                  primary: "rgb(231 70 102)",
                },
              })}
            />
          </HStack> */}
        </HStack>
        {!loading && (
          <VStack justify={"center"} w={"100%"} minH={"50vh"}>
            <Spinner
              thickness="5px"
              speed="0.55s"
              emptyColor="gray.200"
              color="red.400"
              size="xl"
            />
          </VStack>
        )}
        {data.length && loading ? (
        <TableContainer
          id="otpHistory"
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"99%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["sm", "sm", "md", "md"]}
            // overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Date</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Service</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Price</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Mobile</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Status</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Otp</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Left</Th>
                <Th color={"white"} p={'0.8rem 0.1rem !important'} textAlign={'center'}>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <OtpHistoryCard
                    key={el._id}
                    serviceName={el?.serviceId?.serviceName || ""}
                    otpMessages={el.otpIds}
                    time={el.time}
                    price={el.price}
                    status={el.status}
                    mobile={el.mobileno}
                    mobileId={el._id}
                    serialNumber={el?.serialNumber || ""}
                    handleOTPCancel={handleOTPCancel}
                    handleOTPMessage={handleOTPMessage}
                    server={el?.serviceId?.server}
                    businessCode={el?.serviceId?.serviceCode}
                    cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>) : <></>}
        {totalPages > 2 && (
          <HStack gap={['0.2rem', '0.5rem', '0.9rem', "1rem"]} align={"center"}>
            <ChakraSelect
              onChange={(e) => {
                setLimitNo(Number(e.target.value));
                setPageNo(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
              <option value={80}>80</option>
              <option value={100}>100</option>
            </ChakraSelect>
            <Button
              onClick={() => {
                setPageNo((pre) => {
                  if (pre > 1) {
                    return pre - 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              {"<<"}
            </Button>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {pageNo}
            </Text>
            <Text>/</Text>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {totalPages}
            </Text>
            <Button
              onClick={() => {
                setPageNo((pre) => {
                  if (pre < totalPages) {
                    return pre + 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              {">>"}
            </Button>
          </HStack>
        )}
        {!data.length && loading ? (
          <Empty title={"Otp history is Empty!!!"} />
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
};

export default OtpHistory;