import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react'
import { Helmet } from 'react-helmet';
import { BsTelegram, BsYoutube } from "react-icons/bs";
import { RoughNotation } from 'react-rough-notation';
const QA = [
    {
        question: "How can we take discount on any service ?", answer: `If you buy in bulk you will get a discount The more you buy, the more discount you get If you want to know more about this then you can discuss with our customer support.`
    },
    {
        question: "How can we get extra bonus on recharge ?", answer: `Yes you can get bonus on recharge The more you recharge, the more bonus you will get`
    },
    {
        question: "The service we want is not available in the website. How can we add a new service ?", answer: `You can get the service which is not available added through customer support This will take some detail or please share the details with our customer support`
    },
    {
        question: "We need API so how can we get it ?", answer: `Currently, we have removed the API option for some reason. If you need it, you can discuss with our customer support and get it.`
    },
    {
        question: "We need a special panel for selling, we will sell to our clients how can we get it ?", answer: `We have special panel available for selling If you want to sell your customer then you can do it through Or you have a team you want to work with through this For further information discuss with our customer support`
    }
]
const Support = () => {

    return (
        <>
            <Helmet>
                <title>Support | Techy India</title>
            </Helmet>
            <VStack
                gap={"1rem"}
                bg={"white"}
                align={"start"}
                justify={"start"}
                height={"100%"}
                minH={"100vh"}
                w={['100%', '100%', '100%', "79%"]}
                p={"1.5rem"}
            >
                <Heading>
                    Our
                    <RoughNotation
                        strokeWidth={2}
                        color={"rgb(220, 20, 60)"}
                        type="underline"
                        show={true}
                    >
                        {` Support`}
                    </RoughNotation>
                </Heading>
                <VStack w={'100%'} mt={'2rem !important'}>
                    <Accordion allowToggle w={['100%', '90%', '80%', "80%"]}>
                        {QA.map((el) => (
                            <AccordionItem key={el.question}>
                                <h2>
                                    <AccordionButton
                                        outline={'none'}
                                        border={'none'}
                                        bg={"white"}
                                        rounded={'1px'}
                                        _hover={{
                                            bg: "#EDF2F8",
                                        }}
                                        color={"black"}
                                        _expanded={{ bg: "#EDF2F8", color: "black" }}
                                    >
                                        <Box as="span" flex="1" textAlign="left">
                                            {el.question}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel bgColor={"white"} color={"rgb(220, 20, 60)"}>
                                    {el.answer}
                                    <Box as='span' cursor={'pointer'} color={'blue'} onClick={() => { window.open("https://telegram.me/techyindiaxyzSupport", "_blank"); }}> Click here</Box>
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>

                </VStack>
                <HStack w={'100%'} mt={'2rem'} justify={'center'}>
                    <Button

                        p={[
                            "1.15rem 0.65rem",
                            "1.2rem 0.65rem",
                            "1.4rem 0.8rem",
                            "1.4rem 0.8rem",
                        ]}
                        outline={"none !important"}
                        rightIcon={
                            <BsTelegram />
                        }
                        onClick={() => { window.open("https://telegram.me/techyindiaxyzofficial", "_blank"); }}
                        colorScheme="white"
                        fontSize={["1.2rem", "1.3rem", "1.4rem", "1.4rem"]}
                        fontWeight={700}
                        color={'rgb(51, 144, 236)'}
                        border={"1px solid black"}
                        w={"8.6rem"}>
                        Telegram
                    </Button>
                    <Button
                        p={[
                            "1.15rem 0.65rem",
                            "1.2rem 0.65rem",
                            "1.4rem 0.8rem",
                            "1.4rem 0.8rem",
                        ]}
                        outline={"none !important"}
                        rightIcon={
                            <BsYoutube />
                        }
                        onClick={() => { window.open("https://www.youtube.com/@techyindia576", "_blank"); }}
                        colorScheme="white"
                        fontSize={["1.2rem", "1.3rem", "1.4rem", "1.4rem"]}
                        fontWeight={700}
                        color={'#CD201F'}
                        border={"1px solid black"}
                        w={"8.6rem"}>
                        Youtube
                    </Button>
                </HStack>
            </VStack>
        </>
    )
}

export default Support