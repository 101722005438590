import {
    VStack,
    Button,
    HStack,
    useToast,
    Heading,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Select,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { RoughNotation } from "react-rough-notation";
import { FiLogOut } from "react-icons/fi";
import { Helmet } from "react-helmet";
import { BsFillCreditCardFill } from "react-icons/bs";
import { DefaultServer, LogoutApi } from "../../utils/api";
const Setting = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { Token, setIsAuth, defaultServer, setDefaultServer,
        userEmail } = useContext(AppContext)
    const toast = useToast();
    const handleLogout = () => {
        LogoutApi(Token).then((res) => {
            onClose();
            localStorage.removeItem("token");
            setIsAuth(false);
            toast({
                status: "success",
                title: "Successfully logout",
                isClosable: true,
                duration: 2000,
            });

        }).catch((err) => {
            toast({
                title: err.response?.data?.message || 'Error contact admin',
                status: "error",
                isClosable: false,
            });

        })
    };
    const handleDefaultServer = (event) => {
        setDefaultServer(event.target.value)
        DefaultServer(Token, event.target.value).then((res) => {
            toast({
                status: 'success',
                title: res.data.message,
                isClosable: true
            })
        }).catch((err) => {
            console.log(err, 'chekcing error');
            toast({
                title: err.response?.data?.message || 'Error contact admin',
                status: "error",
                isClosable: false,
            });
        })
    }
    return (
        <>
            <Helmet>
                <title>Setting | Techy India</title>
            </Helmet>
            <VStack
                gap={"1rem"}
                bg={"white"}
                align={"start"}
                justify={"start"}
                height={"100%"}
                minH={"100vh"}
                w={["97%", "100%", "100%", "79%"]}
                p={"1.5rem"}
            >
                <Heading>
                    Customise
                    <RoughNotation
                        strokeWidth={2}
                        color={"rgb(220, 20, 60)"}
                        type="underline"
                        show={true}
                    >
                        {` Setting `}
                    </RoughNotation>
                </Heading>
                <VStack gap={'1rem'} w={['97%', '100%', '100%', '100%']} align={'start'} justify={'start'}>
                    <Text color={'gray'}>{userEmail}</Text>
                    <VStack>
                        <Heading size={'md'}>Default Server</Heading>
                        <Select onChange={handleDefaultServer} value={defaultServer}>
                            <option value="server1">Operator1</option>
                            <option value="server2">Operator2
                            </option>
                            <option value="server3">Operator3
                            </option>
                        </Select>
                    </VStack>
                    <Button
                        outline={"none !important"}
                        _hover={{
                            bgColor: "white",
                            color: "rgb(220, 20, 60)",
                        }}
                        rightIcon={<FiLogOut />}
                        onClick={() => { onOpen() }}
                        colorScheme="white"
                        fontSize={"1.1rem"}
                        fontWeight={700}
                        bgColor={"rgb(220, 20, 60)"}
                        color={'white'}
                        border={"2px solid rgb(220, 20, 60) !important"}
                    >
                        Logout
                    </Button>
                </VStack>
            </VStack>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"30vh"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                    >
                        <Button
                            onClick={handleLogout}
                            _hover={{
                                bgColor: "white",
                                color: "rgb(220, 20, 60)",
                            }}
                            color={"white"}
                            bgColor={"rgb(220, 20, 60)"}
                            border={"2.4px solid rgb(220, 20, 60) !important"}
                        >
                            Logout
                        </Button>
                        <Button
                            onClick={onClose}
                            color={"rgb(220, 20, 60)"}
                            bg={"white"}
                            border={"2.4px solid rgb(220, 20, 60) !important"}
                            _hover={{
                                bgColor: "rgb(220, 20, 60)",
                                color: "white",
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default Setting;
