import {
  AspectRatio,
  Box,
  Button,
  Heading,
  Input,
  Text,
  HStack,
  useToast,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { BsUnlock } from "react-icons/bs";
import { Link } from "react-router-dom";
const Forgot = () => {
  const [email, setEmail] = useState("");
  const { forgotPassword } = useContext(AppContext);
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email != "") {
      forgotPassword(email);
    }
  };
  return (
    <Box w="100%">
      <VStack justify={"center"} align={"center"} minH={"80vh"} gap={"2rem"}>
        <HStack justify={"center"}>
          <Heading
          pt={'2rem'}
            display={"flex"}
            textAlign={'center'}
            gap={"1rem"}
            size="2xl"
            color="rgb(218 52 85)"
          >
            {isLargerThan600 ? <BsUnlock /> : <></>}Forgot your
            password
          </Heading>
        </HStack>
        <VStack
          w={['90%','75%',"50%","45%"]}
          p={['3rem 1.5rem','4rem 2.5rem','5rem 3rem',"5rem 3rem"]}
          align={"center"}
          border={"1.5px solid rgb(218 52 85)"}
          rounded={"0.3rem"}
        >
          <form onSubmit={handleSubmit}>
            <Text my={3}>Email</Text>
            <Input
              mb={8}
              w="100%"
              required={true}
              placeholder="Email"
              type={"email"}
              variant="outline"
              size="lg"
              border="2.5px solid rgb(218 52 85)"
              _hover={{ background: "#ffffff", color: "black" }}
              onChange={handleChange}
            />
            <Button
              w="100%"
              h="48px"
              type="submit"
              border={"1.5px solid rgb(218 52 85) !important"}
              bg="rgb(218 52 85)"
              color={"white"}
              _hover={{
                color: "rgb(218 52 85)",
                background: "white",
              }}
            >
              Reset Password
            </Button>
          </form>
          <Text
            position={"relative"}
            top={"2rem"}
            textAlign={"center"}
            mt={"2rem"}
          >
            I've remember my password &nbsp;
            <Link id="login" to="/login">
              Login
            </Link>
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Forgot;
