import {
  Button,
  Td,
  Tr,
  Select as ChakraSelect, useToast
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { EditDiscount } from "../../../utils/api";

const PaymentCard = ({ id, email, balance, OpenBlockModal, discount, AdminToken }) => {
  const discountRef1 = useRef();
  const discountRef2 = useRef();
  const discountRef3 = useRef();
  const toast = useToast();
  useEffect(() => {
    console.log(discount, 'chekcing')
    let a = discount?.find((el) => el.server == 'server1') || { discount: 0 };
    let b = discount?.find((el) => el.server == 'server2') || { discount: 0 };
    let c = discount?.find((el) => el.server == 'server3') || { discount: 0 };
    discountRef1.current.value = a.discount;
    discountRef2.current.value = b.discount;
    discountRef3.current.value = c.discount;
  }, []);
  const handleDiscountChange = (server, event) => {
    console.log(event.target.value);
    EditDiscount(AdminToken, { server, discount: event.target.value == '' ? 0 : event.target.value, userId: id }).then((res) => {
      console.log(res.data);
      toast({
        title: "Discount updated successfully",
        isClosable: true,
        duration: 2000,
        status: "success"
      })
    }).catch((err) => {
      console.log(err)
      toast({
        title: err.response?.data?.message
          ? err.response?.data.message
          : err.message,
        isClosable: true,
        status: 'error'
      })
    })
  }
  return (
    <>
      <Tr>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{email}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{balance}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
          <Button
            _hover={{
              bgColor: "rgb(220, 20, 60)",
              color: "white",
            }}
            w={'50%'}
            color={"rgb(220, 20, 60)"}
            bgColor={"white"}
            h={"2.35rem"}
            p={0}
            fontSize={"0.97rem"}
            border={"1px solid rgb(220, 20, 60) !important"}
            onClick={() => OpenBlockModal(id, { balance, email })}
          >
            Edit
          </Button>
        </Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
          <ChakraSelect onChange={(event) => handleDiscountChange('server2', event)} ref={discountRef2} placeholder={"Server 1 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
          <ChakraSelect onChange={(event) => handleDiscountChange('server3', event)} ref={discountRef3} placeholder={"Server 2 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
          <ChakraSelect onChange={(event) => handleDiscountChange('server1', event)} ref={discountRef1} placeholder={"Server 3 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
        </Td>
      </Tr>
    </>
  );
};

export default PaymentCard;
