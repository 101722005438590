import { Button, HStack, Image, Input, InputGroup, InputRightElement, Text, VStack, useStatStyles, useToast } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react'
import bharatPay from "../../../assets/bharat-pay-qr.jpg";
import { AppContext } from '../../../context/AppContext';
import { GetAllCurrency, GetUpi, SubmitTransactionAPI } from '../../../utils/api';
import { FiCopy } from "react-icons/fi";
const UPI = () => {
    const { Token, setBalance } = useContext(AppContext);
    const toast = useToast();
    const [showService, setShowService] = useState("");
    const [usdtPrice, setUsdtPrice] = useState(0)
    const [trxPrice, setTrxPrice] = useState(0);
    const [upiImg,setUpiImg] = useState('');
    const [upiId,setUpiId] = useState('');
    const inputRef = useRef();
    useEffect(() => {
        GetUpi(Token).then((res)=>{
            setUpiImg(res.data.data.imgURL);
            setUpiId(res.data.data.upiId);
        })
        GetAllCurrency(Token).then((res) => {
            res.data.currencies.map((el) => {
                if (el.currencyName == 'USDT') {
                    setUsdtPrice(el.currencyPrice);
                }
                if (el.currencyName == 'TRX') {
                    setTrxPrice(el.currencyPrice);
                }
            })
        }).catch((err) => {
            // setLoading(true);
            console.log(err, 'error saying');
        });
    }, []);
    const CopyToClipBoard = () => {
        navigator.clipboard
            .writeText(upiId)
            .then(() => {
                toast({
                    status: "info",
                    title: `Address copied`,
                    isClosable: true,
                    duration: 1000,
                    position: "bottom-right",
                });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
                toast({
                    status: "error",
                    title: `errror in copying address`,
                    isClosable: true,
                    duration: 1000,
                });
            });
    };
    const handleTransaction = () => {
        if (inputRef.current.value == "") {
            toast({
                status: "warning",
                title: "Please Enter Transaction Id",
                isClosable: true,
                duration: 1000,
            });
            return;
        }
        SubmitTransactionAPI(Token, { transactionId: inputRef.current.value, method: 'bharatpay' })
            .then((res) => {
                console.log(res.data, "successfull");
                setBalance(res.data.balance);
                toast({
                    title: "Congratulations 🎊",
                    status: "success",
                    description: `Your ${res.data.transaction.amount}rs is successfully added`,
                    isClosable: true,
                    duration: 4000,
                });
            })
            .catch((err) => {
                toast({
                    status: "error",
                    title: err.response?.data.message,
                    isClosable: true,
                });
                console.log(err, "saying error");
            });
    };
    return (
        <VStack w={"100%"} mt={['3rem', '2rem', '1rem', '0.1rem']}>
            <Text fontSize={['1.45rem', '1.5rem', '1.6rem', "1.6rem"]} fontWeight={700}>
                SCAN QR CODE
            </Text>
            <Image minH={'20vh'} w={['94%','60%','35%','30%']} src={upiImg} alt="bharat pay qr" />
            {/* <Text>Note :- Minimum amount 100rs</Text> */}
            <HStack
                gap={"2rem"}
                justify={"center"}
                border={"1px solid transparent"}
                p={"1rem"}
                w={['100%', '70%', '60%', "40%"]}
            >

            </HStack>
            <VStack
                mt={"0.1rem !important"}
                gap={"1.2rem"}
                align={"start"}
                w={['95%', '70%', '65%', "50%"]}
            >
                <HStack
                    border={"1px solid transparent"}
                    justify={"start"}
                    w={"100%"}
                    align={['flex-start', 'flex-start', 'flex-end', "flex-end"]}
                    gap={['0.5rem', '0.5rem', '1rem', "1rem"]}
                    flexDirection={['column', 'column', 'row', 'row']}
                >
                    <Text pb={"0.1rem"} fontSize={['1.1rem', '1.15rem', '1.2rem', "1.3rem"]} fontWeight={600}>
                        Upi id  :-
                    </Text>
                    <InputGroup w={['100%', '95%', '80%', "70%"]}>
                        <Input
                            pl={['0.2rem', '0.4rem', '1rem', '1rem']}
                            fontSize={['0.8rem', '0.9rem', '1rem', '1rem']}
                            value={upiId}
                            readOnly
                        />
                        <InputRightElement>
                            <Button
                                bg={"white !important"}
                                p={"0 !important"}
                                h={"96%"}
                                border={"1px solid gray !important"}
                                color={
                                    showService == "tether" ? "#289C7A" : "rgb(220, 20, 60)"
                                }
                                onClick={CopyToClipBoard}
                            >
                                <FiCopy />
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </HStack>
                <VStack>
                    <Text>After payment copy the UTR / Reference number and click on submit button you can use any upi payment method to deposit</Text>
                    <Text>If you have not received a deposit to your account within the 1 hours  please contact the customer support</Text>
                </VStack>
                <HStack w={"100%"}>
                    <Input ref={inputRef} placeholder="Enter UTR ID / Reference number" />
                    <Button
                        _hover={{
                            bgColor: "white",
                            color: "rgb(220, 20, 60)",
                        }}
                        w={"28%"}
                        isLoading={false}
                        loadingText={"Please Wait"}
                        spinnerPlacement="end"
                        onClick={handleTransaction}
                        fontSize={"1.1rem"}
                        color={"white"}
                        bgColor={"rgb(220, 20, 60)"}
                        border={"2px solid rgb(220, 20, 60) !important"}
                    >
                        Submit
                    </Button>
                </HStack>
            </VStack>

        </VStack>
    )
}

export default UPI
