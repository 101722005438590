function getStartAndEndDateOfWeek() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // Get the current day of the week (0-6)
  
    const diff = (currentDay === 0 ? 6 : currentDay - 1); // Calculate the difference in days to Monday
    let startOfWeek = new Date(currentDate); // Create a new Date object for the current date
    startOfWeek.setDate(currentDate.getDate() - diff); // Subtract the difference in days to get the start of the week
  
    let endOfWeek = new Date(startOfWeek); // Create a new Date object for the start of the week
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Add 6 days to get the end of the week (Sunday)
  
    startOfWeek = startOfWeek.toISOString().split("T")[0];
    endOfWeek = endOfWeek.toISOString().split("T")[0];
  
    return {
      startOfWeek,
      endOfWeek
    };
  }
  export {getStartAndEndDateOfWeek};