import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Td,
    Tr,
    useDisclosure,
  } from "@chakra-ui/react";
  import React from "react";
  
  const BlockCard = ({ name, email, balance, id,OpenBlockModal }) => {
    return (
      <>
        <Tr>
          <Td pt={'0.7rem !important'} pb={'0.7rem !important'}   borderBottom={"1px solid rgb(229 60 94)"}>{name || "user"}</Td>
          <Td  pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{email}</Td>
          <Td  pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{balance || 0}</Td>
          <Td  pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
            <Button
              _hover={{
                bgColor: "rgb(220, 20, 60)",
                color: "white",
              }}
              color={"rgb(220, 20, 60)"}
              bgColor={"white"}
              w={"60%"}
              h={"2.35rem"}
              p={0}
              fontSize={"0.97rem"}
              border={"1px solid rgb(220, 20, 60) !important"}
              onClick={() => OpenBlockModal(id)}
            >
               UnBlock
            </Button>
          </Td>
        </Tr>
      </>
    );
  };
  
  export default BlockCard;
  