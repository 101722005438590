import { Button, HStack, Input, Text, VStack, useToast } from '@chakra-ui/react';
import React, { useContext, useRef } from 'react'
import { AppContext } from '../../../context/AppContext';
import { UseCouponCode } from '../../../utils/api';
const PromoCode = () => {
    const { Token, setBalance } = useContext(AppContext);
    const toast = useToast();
    const inputRef = useRef();
    const handlePromoCode = () => {
        if (inputRef.current.value == "") {
            toast({
                status: "warning",
                title: "Please Enter CouponCode",
                isClosable: true,
                duration: 1000,
            });
            return;
        }
        UseCouponCode(Token, { couponCode: inputRef.current.value.trim() })
            .then((res) => {
                if (res.data.status == 'expired') {
                    toast({
                        status: "info",
                        description: `${res.data.message}`,
                        isClosable: true,
                        duration: 3000,
                    });
                    return;
                }
                setBalance(res.data.balance);
                toast({
                    title: "Congratulations 🎊",
                    status: "success",
                    description: `${res.data.message}`,
                    isClosable: true,
                    duration: 4000,
                });
            })
            .catch((err) => {
                toast({
                    status: "error",
                    title: err.response?.data.message,
                    isClosable: true,
                });
                console.log(err, "saying error");
            });
    };
    return (
        <VStack w={"100%"} mt={['3rem', '2rem', '1rem', '0.1rem']}>
            <Text fontSize={['1.45rem', '1.5rem', '1.6rem', "1.6rem"]} fontWeight={700}>
                Enter the Promocode below
            </Text>
            <VStack w={"100%"} mt={["3rem", "2rem", "1rem", "0.1rem"]}>
                <HStack w={["90","70%","50%","50%"]}>
                    <Input ref={inputRef} placeholder="Enter promo code" />
                    <Button
                        _hover={{
                            bgColor: "white",
                            color: "rgb(220, 20, 60)",
                        }}
                        w={"28%"}
                        isLoading={false}
                        loadingText={"Please Wait"}
                        spinnerPlacement="end"
                        onClick={handlePromoCode}
                        fontSize={"1.1rem"}
                        color={"white"}
                        bgColor={"rgb(220, 20, 60)"}
                        border={"2px solid rgb(220, 20, 60) !important"}
                    >
                        Submit
                    </Button>
                </HStack>
            </VStack>
        </VStack>
    )
}

export default PromoCode;
