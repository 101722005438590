import React, { useContext, useEffect } from "react";
import LeftSideBar from "../../components/LeftSideBar";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
  Box,
  Link as ChakraLink,
  useToast
} from "@chakra-ui/react";
import { AppContext } from "../../context/AppContext";
import Navbar from "../../components/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import { FaTelegram } from 'react-icons/fa';
import SelectService from "../../components/SelectService";
import { GetBalanceAPI, LogoutApi } from "../../utils/api";
const Home = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isDark, Token, setBalance, balance, handleLogout, setDefaultServer, defaultServer, setUserEmail } = useContext(AppContext);
  const location = useLocation();
  const toast = useToast();
  const [isLargerThan992] = useMediaQuery("(min-width: 992px)");
  useEffect(() => {
    GetBalanceAPI(Token).then((res) => {
      console.log(res.data.defaultServer, 'checking home data');
      setBalance(res.data.balance);
      setDefaultServer(res.data.defaultServer)
      setUserEmail(res.data.email);
    }).catch((err) => {
      handleLogout(err);
    })
  }, []);
  return (
    <VStack w={"100%"}>
      <Navbar onOpen={onOpen} />
      <HStack
        align={"start"}
        mt={"0 !important"}
        minH={"100%"}
        justify={"start"}
        w={"100%"}
      >
        {isLargerThan992 ? <LeftSideBar /> : <></>}
        {location.pathname == "/" && <SelectService server={defaultServer} />}
        <Drawer
          placement={"left"}
          onClose={onClose}
          isOpen={isOpen}
          size={["xs", "sm", "md", "md"]}
        >
          <DrawerOverlay />
          <DrawerContent bgColor={"#EDF2F8"}>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              Balance{" "}
              <span style={{ fontSize: "0.92rem", color: "rgb(220, 20, 60)" }}>
                {" "}
                ₹{Number(balance).toFixed(1)}
              </span>{" "}
            </DrawerHeader>
            <DrawerBody>
              <LeftSideBar closeDrawer={onClose} status={true} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Box zIndex={10} position={'fixed'} bottom={['0.5rem', '0.9rem', '1rem', '1rem']} right={['0.5rem', '0.9rem', '1rem', '1rem']} fontSize={'3rem'} >
          <ChakraLink href="https://telegram.me/techyindiaxyzofficial" target={"_blank"}>
            <FaTelegram style={{
              color: '#3390EC',
              cursor: 'pointer'
            }} />
          </ChakraLink>
        </Box>
        <Outlet />
      </HStack>
    </VStack>
  );
};

export default Home;
