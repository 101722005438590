import { Button, Td, Textarea, Tr } from "@chakra-ui/react";
import React from "react";

const TransactionCard = ({ transactionId, time, price,type }) => {
  // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
  return (
    <>
      <Tr>
        <Td
          fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
          pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          ₹{price}
        </Td>
        <Td
          fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
          pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          <Textarea
            readOnly={true}
            value={transactionId}
            w={['13rem', '16rem', '100%', "100%"]}
            size={['sm', "sm", 'md', 'md']} />
        </Td>
        <Td
          fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
          pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          {type}
        </Td>
        <Td
          fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
          pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          {time}
        </Td>
      </Tr>
    </>
  );
};

export default TransactionCard;
