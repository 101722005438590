import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Empty from "../../../components/Empty";
import { AppContext } from "../../../context/AppContext";
import { GetAllBlockedUsers, GetAllUsers, SetBlock } from "../../../utils/api";
import BlockCard from "../components/BlockCard";

const BlockedUser = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const toast = useToast();
  const {AdminToken} = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [checkUser,setCheckUser] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const OpenBlockModal = (userId) => {
    setCheckUser(userId);
    onOpen();
  };
  const handleSetBlock = () => {
    SetBlock({ userId: checkUser, block: true }, AdminToken)
      .then((res) => {
        toast({
          title: `User successfully Unblocked`,
          status: "warning",
          isClosable: true,
          duration: 1500,
        });
        let currentData = data;
        currentData = currentData.filter((el) => el._id!==checkUser);
        setData([...currentData]);
        onClose();
      })
      .catch((err) => {
        onClose();
        console.log(err);
      });
  };
  useEffect(() => {
    setLoading(false);
    GetAllBlockedUsers(AdminToken,pageNo,limitNo)
      .then((res) => {
        setLoading(true);
        setData(res.data.users);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo,pageNo]);
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Email</Th>
                <Th color={"white"}>Balance</Th>
                <Th color={"white"}>Blocked</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <BlockCard
                    OpenBlockModal={OpenBlockModal}
                    key={el._id}
                    id={el._id}
                    email={el.email}
                    name={el.name || "user"}
                    balance={el.balance || 100}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {totalPages>1&&data.length&&<HStack gap={"1rem"} align={"center"}>
                <Select
                    onChange={(e) => {
                        setLimitNo(Number(e.target.value));
                        setPageNo(1);
                    }}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={40}>40</option>
                    <option value={60}>60</option>
                    <option value={80}>80</option>
                    <option value={100}>100</option>
                </Select>
                <Button
                    onClick={() => {
                        setPageNo((pre) => {
                            if (pre > 1) {
                                return pre - 1;
                            } else {
                                return pre;
                            }
                        });
                    }}
                >
                    {"<<"}
                </Button>
                <Text
                    padding={"0.5rem 1rem"}
                    border={"1px solid gray"}
                    borderRadius={"5px"}
                >
                    {pageNo}
                </Text>
                <Text>/</Text>
                <Text
                    padding={"0.5rem 1rem"}
                    border={"1px solid gray"}
                    borderRadius={"5px"}
                >
                    {totalPages}
                </Text>
                <Button
                    onClick={() => {
                        setPageNo((pre) => {
                            if (pre < totalPages) {
                                return pre + 1;
                            } else {
                                return pre;
                            }
                        });
                    }}
                >
                    {">>"}
                </Button>
            </HStack>}
      {!data.length && loading ? <Empty title={"No Blocked user found!!!"} /> : <></>}
      {/* Block User Modal  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            flexDirection={"column"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
          >
            <Text fontSize={"1.4rem"} fontWeight={"600"}>
              Are you sure to unblock user!!!
            </Text>
            <HStack>
              <Button
                onClick={() => {
                  handleSetBlock();
                }}
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
              > Confirm
              </Button>
              <Button
                color={"rgb(220, 20, 60)"}
                bg={"white"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
                _hover={{
                  bgColor: "rgb(220, 20, 60)",
                  color: "white",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default BlockedUser;
