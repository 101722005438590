import {
    VStack,
    Button,
    HStack,
    useToast,
    Heading,
    Text,
    AccordionItem,
    AccordionPanel,
    Accordion, AccordionButton, Box, AccordionIcon
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { RoughNotation } from "react-rough-notation";
import { Helmet } from "react-helmet";
const QA = [
    {
        question: "How  to Register in the system?", answer: `On the main page of our site there is a button "Register". You just need to enter your , email address and add your password twice.`
    },
    {
        question: "How to recharge the balance?", answer: `After successful registration you will be taken to your personal account After clicking on three dot, the option of recharge will come, after clicking on recharge, you will be forwarded to the payment page.`
    },
    {
        question: "I refilled the balance, but the money did not arrive, what should I do?", answer: `You can safely write about this to us. But! We want to notify you that the terms for crediting funds to your personal account in the system are not always the same`
    },
    {
        question: "How quickly do SMS messages arrive?", answer: `The phone number you rent stay active to up to 20 minutes, which is enough to get the SMS code and complete the activation process.`
    },
    {
        question: "I took the number, but the SMS did not come, or the number is already registered on the service.", answer: `If you did not receive the code from the service, then the money will not be withdrawn from your balance. We do not have penalties for unused numbers, but if you abuse, we reserve the right to ban you.`
    },
    {
        question: "I received the code, but it does not fit, you made a mistake and gave me the wrong code, return the money!", answer: `If the code came, then we consider your activation paid regardless of whether it came up to you or not, because we can not verify it. If the code does not suit you, you can press the repeat button and request another code for this number - it's free, you can do it as many times as you like, there are no restrictions on SMS from one service to one number.`
    },
    {
        question: "How to change the password? I have suspicions that my account was hacked.", answer: `If you doubt that you are using the account solely, then write about it in technical support - we "freeze" the account before changing the password. To change the password yourself, go to the "Account settings" tab, enter the old password and create a new one twice`
    },
]
const Faq = () => {


    return (
        <>
            <Helmet>
                <title>Faq | Techy India</title>
            </Helmet>
            <VStack
                gap={"1rem"}
                bg={"white"}
                align={"start"}
                justify={"start"}
                height={"100%"}
                minH={"100vh"}
                w={['100%', '100%', '100%', "79%"]}
                p={"1.5rem"}
            >
                <Heading>
                    FAQ
                    <RoughNotation
                        strokeWidth={2}
                        color={"rgb(220, 20, 60)"}
                        type="underline"
                        show={true}
                    >
                        {` General `}
                    </RoughNotation>
                    Questions
                </Heading>
                <VStack w={'100%'} mt={'2rem !important'}>
                    <Accordion allowToggle w={['100%', '90%', '80%', "80%"]}>
                        {QA.map((el) => (
                            <AccordionItem key={el.question}>
                                <h2>
                                    <AccordionButton
                                        outline={'none'}
                                        border={'none'}
                                        bg={"white"}
                                        rounded={'1px'}
                                        _hover={{
                                            bg: "#EDF2F8",
                                        }}
                                        color={"black"}
                                        _expanded={{ bg: "#EDF2F8", color: "black" }}
                                    >
                                        <Box as="span" flex="1" textAlign="left">
                                            {el.question}
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel bgColor={"white"} color={"rgb(220, 20, 60)"}>
                                    {el.answer}

                                </AccordionPanel>
                            </AccordionItem>

                        ))}
                    </Accordion>

                </VStack>
            </VStack>
        </>
    );
};

export default Faq;
