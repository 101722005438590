import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import RequireAuth from "../components/RequireAuth";
import Signup from "./Signup";
import Forgot from "./Forgot";
import Admin from "./Admin/Admin";
import AdminAuth from "../components/AdminAuth";
import Users from "./Admin/Users/Users";
import Dashboard from "./Admin/Dashboard/Dashboard";
import BlockedUser from "./Admin/BlockedUser/BlockedUser";
import Services from "./Admin/Services/Services";
import Server1 from "./Server1/Server1";
import GetOtp from "./GetOtp";
import OtpHistory from "./OtpHistory/OtpHistory";
import SingleUser from "./Admin/Users/SingleUser";
import Recharge from "./Recharge/Recharge";
import Transaction from "./Transaction/Transaction";
import Payment from "./Admin/Payment/Payment";
import Server2 from "./Server2/Server2";
import API from "./API/API";
import Currency from "./Admin/Currency/Currency";
import UPI from "./Recharge/UPI/UPI";
import Crypto from "./Recharge/Crypto/Crypto";
import Faq from "./Faq/Faq";
import Server3 from "./Server3/Server3";
import DailyStats from "./Admin/DailyStats/DailyStats";
import Support from "./Support/Support";
import Coupon from "./Admin/Coupon";
import PromoCode from "./Recharge/PromoCode";
import TransactionsHistory from "./Admin/TransactionsHistory";
import Setting from "./Setting";
import Referral from "./Referral";
import NotAllowed from './NotAllowed';
import OAuth from "./OAuth/OAuth";
const AllRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <RequireAuth>
            <NotAllowed>
              <Home />
            </NotAllowed>
          </RequireAuth>
        }
        path="/"
      >
        <Route path={"server1"} element={<Server1 />} />
        <Route path={"server2"} element={<Server2 />} />
        <Route path={"server3"} element={<Server3 />} />
        <Route path={"getotp"} element={<GetOtp />} />
        <Route path={"otphistory"} element={<OtpHistory />} />
        <Route path={"setting"} element={<Setting />} />
        <Route path={"referral"} element={<Referral />} />
        <Route path={"faq"} element={<Faq />} />
        <Route path={"support"} element={<Support />} />
        <Route path={"/payment"} element={<Recharge />}>
          <Route path={"upi"} element={<UPI />} />
          <Route path={"crypto"} element={<Crypto />} />
          <Route path={"promocode"} element={<PromoCode />} />
        </Route>

        {/* <Route path={"api"} element={<API />} /> */}
        <Route path={"transaction"} element={<Transaction />} />
      </Route>
      <Route element={<Login />} path="/login" />
      <Route element={<Signup />} path="/signup" />
      <Route element={<OAuth />} path="/account/login-success" />
      <Route
        path={"/admin"}
        element={
          <AdminAuth>
            <Admin />
          </AdminAuth>
        }
      >
        <Route path={"daily"} element={<DailyStats />} />
        <Route path={"coupon"} element={<Coupon />} />
        <Route path={"recharge"} element={<Payment />} />
        <Route path={"dashboard"} element={<Dashboard />} />
        <Route path={"users"} element={<Users />} />
        <Route path={"users/:id"} element={<SingleUser />} />
        <Route path={"blockedusers"} element={<BlockedUser />} />
        <Route path={"services"} element={<Services />} />
        <Route path={"currency"} element={<Currency />} />
        <Route path={"transaction"} element={<TransactionsHistory />} />
      </Route>
      <Route element={<Forgot />} path="/forgot_password" />
    </Routes>
  );
};
export default AllRoutes;
