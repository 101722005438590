import { Grid, GridItem, HStack, VStack, Text, Box } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import AdminCard from "./components/AdminCard";
import AdminLeftSideBar from "./components/AdminLeftSideBar";
import { Outlet, useLocation } from "react-router-dom";
import DashboardCard from "./components/DashboardCard";
import {Helmet} from "react-helmet";
const Admin = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>Admin | Techy India</title>
      </Helmet>
    <HStack align={"start"}  justify={"start"} w={"100%"}>
      <AdminLeftSideBar />
      {location.pathname == "/admin" && <DashboardCard />}
      {location.pathname == "/admin/" && <DashboardCard />}
      <Outlet />
    </HStack>
    </>
  );
};

export default Admin;
