import { Button, Td, Textarea, Tr } from "@chakra-ui/react";
import React from "react";

const SingleOtpCard = ({ otpMessage, price, time, mobile, serviceName }) => {
    // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
    return (
        <>
            <Tr>
                <Td
                    fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
                    pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    borderBottom={"1px solid rgb(229 60 94)"}
                >
                    {serviceName}
                </Td>
                <Td
                    fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
                    pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    borderBottom={"1px solid rgb(229 60 94)"}
                >
                    ₹{price}
                </Td>
                <Td
                    fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
                    pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    borderBottom={"1px solid rgb(229 60 94)"}
                >
                    {mobile}
                </Td>
                <Td
                    fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
                    pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    borderBottom={"1px solid rgb(229 60 94)"}
                >
                    <Textarea w={['11rem !important', '12rem !important', '14rem !important', "14rem !important"]} rows={2} readOnly={true} value={otpMessage} size={['sm']} />                </Td>
                <Td
                    fontSize={['0.9rem', '0.95rem', '1rem', '1rem']}
                    pb={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    pt={["0.5rem !important", "0.6rem !important", "0.7rem !important", "0.7rem !important"]}
                    borderBottom={"1px solid rgb(229 60 94)"}
                >
                    {time}
                </Td>
            </Tr>
        </>
    );
};

export default SingleOtpCard;
