import {
    Button,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Table,
    TableContainer,
    ModalBody,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Input,
    HStack,
    useToast,
    Spinner,
    Select as ChakraSelect
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import Select from "react-select";
import {
    AddService,
    DeleteServices,
    EditDiscount,
    EditService,
    GetAdminAllServices,
    GetAdminSingleService,
    GetAllServices,
    getDiscountServices,
} from "../../../utils/api";
import Empty from "../../../components/Empty";
import ServicesCard from "../components/ServicesCard";
const Services = () => {
    const addServiceNameRef = useRef();
    const addServiceCodeRef = useRef();
    const addServicePriceRef = useRef();
    const addServerRef = useRef();
    const server1DiscountRef = useRef(null);
    const server2DiscountRef = useRef(null);
    const { AdminToken } = useContext(AppContext);
    const [searched, setSearched] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [editModal, setEditModal] = React.useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [limitNo, setLimitNo] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const toast = useToast();
    const [serviceId, setServiceId] = useState("");
    const [modalStat, setModalStat] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [singleService, setSingleService] = useState({});
    const handleAddService = (event) => {
        event.preventDefault();
        let serviceData = {};
        serviceData.serviceName = addServiceNameRef.current.value;
        serviceData.serviceCode = addServiceCodeRef.current.value;
        serviceData.price = addServicePriceRef.current.value || 10;
        serviceData.server = addServerRef.current.value || 'server1';
        if (!serviceData.serviceName || !serviceData.serviceCode) {
            toast({
                title: `Please Fill All Input Boxes`,
                status: "warning",
                isClosable: true,
                duration: 3000,
            });
        } else {
            AddService(serviceData, AdminToken)
                .then((res) => {
                    toast({
                        title: `Service Added Successfully`,
                        status: "success",
                        isClosable: true,
                        duration: 3000,
                    });
                    onClose();
                })
                .catch((err) => {
                    toast({
                        title: err.response?.data?.message
                            ? err.response?.data.message
                            : err.message,
                        status: "error",
                        isClosable: true,
                        duration: 3000,
                    });
                });
        }
    };
    const handleEditService = (event) => {
        event.preventDefault();
        let Data = {
            serviceCode: addServiceCodeRef.current.value,
            serviceName: addServiceNameRef.current.value,
            price: addServicePriceRef.current.value
        }
        EditService(serviceId, Data, AdminToken).then((res) => {
            toast({
                title: 'service updated successfully!!!',
                isClosable: true,
                status: 'success'
            })
            CloseBlockModal();
            let currentData = data;
            if(searched?.serviceName)setSearched(Data);
            currentData = currentData.map((el) => {
                if (el._id == serviceId) {
                    el = { ...el, ...Data }
                }
                return el;
            });
            if (currentData.length === 0) {
                setPageNo((pre) => { if (pageNo !== 1) { return pre - 1 } return pre });
            }
            setData([...currentData]);
        }).catch((err) => {
            console.log(err);
            toast({
                title: err.response?.data?.message
                    ? err.response?.data.message
                    : err.message,
                isClosable: true,
                status: 'error'
            })
        })
    }
    const handleDelete = () => {
        DeleteServices(serviceId, AdminToken)
            .then((res) => {
                toast({
                    title: 'service deleted successfully!!!',
                    isClosable: true,
                    status: 'warning'
                })
                let currentData = data;
                currentData = currentData.filter((el) => el._id !== serviceId);
                if (currentData.length === 0) {
                    setPageNo((pre) => { if (pageNo !== 1) { return pre - 1 } return pre });
                }
                setData([...currentData]);
                let current2 = searchData;
                // console.log(current2.length, 'debbugging');
                current2 = current2.filter((el) => el.value != serviceId);
                // console.log(current2.length, 'debbugging');
                setSearchData([...current2]);
                setSearched({});
                CloseBlockModal();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message
                        ? err.response?.data.message
                        : err.message,
                    isClosable: true,
                    status: 'error'
                })
            });
    };
    const OpenBlockModal = (serviceId, stat, el) => {
        setServiceId(serviceId);
        if (stat == "delete") {
            setModalStat("delete");
            setDeleteModal(true);
        } else if (stat == "edit") {
            setSingleService(el);
            setModalStat("edit");
            setEditModal(true);
        }
    };
    const CloseBlockModal = () => {
        if (modalStat == "edit") {
            setEditModal(false);
        } else if (modalStat == "delete") {
            setDeleteModal(false);
        }
    };
    useEffect(() => {
        setLoading(false);
        getDiscountServices(AdminToken).then((res) => {
            // console.log(res);
            // console.log(res.data);
            server1DiscountRef.current.value = res.data.server2Discount;
            server2DiscountRef.current.value = res.data.server3Discount;
        }).catch((err) => {
            console.log(err, 'error occured');
        })
        GetAdminAllServices(AdminToken).then((res) => {
            console.log(res.data, 'checking my response of all users')
            setSearchData(res.data.services);
        }).catch((err) => {
            console.log(err, 'error saying');
        })
        GetAllServices(AdminToken, pageNo, limitNo)
            .then((res) => {
                setLoading(true);
                // console.log(res.data);
                setData(res.data.services);
                setTotalPages(res.data.total_pages);
            })
            .catch((err) => {
                setLoading(true);
                console.log(err, "error message");
            });
    }, [limitNo, pageNo]);

    const handleSubmit = (event) => {
        event.preventDefault();
    };
    const handleChange = (event) => {
        if (event.value != '') {
            GetAdminSingleService(AdminToken, event.value).then((res) => {
                setSearched(res.data.service);
            }).catch((err) => {
                console.log(err, 'error saying');
            })
        }
        console.log(event);
    };
    const handleDiscountChange = (server) => {
        EditDiscount(AdminToken, { server, discount: server == 'server2' ? server1DiscountRef.current.value : server2DiscountRef.current.value }).then((res) => {
            console.log(res.data);
            toast({
                title: "Discount updated successfully",
                isClosable: true,
                duration: 2000,
                status: "success"
            })
        }).catch((err) => {
            console.log(err)
            toast({
                title: err.response?.data?.message
                    ? err.response?.data.message
                    : err.message,
                isClosable: true,
                status: 'error'
            })
        })
    }
    return (
        <VStack
            gap={"1rem"}
            bg={"white"}
            align={"end"}
            justify={"start"}
            height={"100%"}
            minH={"100vh"}
            w={"79%"}
            p={"1.5rem"}
        >
            <Button
                _hover={{
                    bgColor: "white",
                    color: "rgb(220, 20, 60)",
                }}
                onClick={onOpen}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
            >
                Add Services
            </Button>
            <VStack align={'self-end'} w={'100%'}>
                <HStack w={'100%'} justify={'start'}>
                    {/* <VStack align={'start'} w={'31%'}>
                        <Text fontWeight={700} pl={'0.14rem'}>
                            Server1 Discount
                        </Text>
                        <ChakraSelect onChange={() => handleDiscountChange('server2')} ref={server1DiscountRef} placeholder={"Server 1 discount"}>
                            <option value="0">0</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="70">70</option>
                            <option value="90">90</option>
                        </ChakraSelect>
                    </VStack>
                    <VStack align={'start'} w={'31%'}>
                        <Text fontWeight={700} pl={'0.14rem'}>
                            Server2 Discount
                        </Text>
                        <ChakraSelect onChange={() => handleDiscountChange('server3')} ref={server2DiscountRef} placeholder={"Server 2 discount"}>
                            <option value="0">0</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="70">70</option>
                            <option value="90">90</option>
                        </ChakraSelect>
                    </VStack> */}
                    <VStack align={'start'} w={'31%'}>
                        <Text fontWeight={700} pl={'0.14rem'}>
                            Search Services
                        </Text>
                        <Select
                            onChange={handleChange}
                            options={searchData}
                            placeholder="Search Service"
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: "rgb(255 227 233 / 63%)",
                                    primary: "rgb(231 70 102)",
                                },
                            })}
                        />
                    </VStack>
                </HStack>
                {
                    searched._id && <TableContainer
                        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                        w={"100%"}
                    >
                        <Table
                            variant="simple"
                            colorScheme="facebook"
                            bgColor="white"
                            size={["md", "lg", "lg"]}
                            overflowX={"auto"}
                            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                        >
                            <Thead
                                background={"rgb(229 60 94)"}
                                color={"white !important"}
                            >
                                <Tr>
                                    <Th color={"white"}>Service Name</Th>
                                    <Th color={"white"}>Service Code</Th>
                                    <Th color={"white"}>Price</Th>
                                    <Th color={"white"}>Edit</Th>
                                    <Th color={"white"}>Delete</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <ServicesCard
                                    OpenBlockModal={OpenBlockModal}
                                    CloseBlockModal={CloseBlockModal}
                                    id={searched._id}
                                    serviceName={searched.serviceName}
                                    serviceCode={searched.serviceCode}
                                    price={searched.price}
                                />
                            </Tbody>
                        </Table>
                    </TableContainer>
                }
            </VStack>
            {!loading && (
                <VStack justify={"center"} w={"100%"} minH={"50vh"}>
                    <Spinner
                        thickness="5px"
                        speed="0.55s"
                        emptyColor="gray.200"
                        color="red.400"
                        size="xl"
                    />
                </VStack>
            )}
            <HStack gap={"1rem"} align={"center"}>
                <ChakraSelect
                    onChange={(e) => {
                        setLimitNo(Number(e.target.value));
                        setPageNo(1);
                    }}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={40}>40</option>
                    <option value={60}>60</option>
                    <option value={80}>80</option>
                    <option value={100}>100</option>
                </ChakraSelect>
                <Button
                    onClick={() => {
                        setPageNo(1);
                    }}
                    padding={"0.5rem 1rem"}
                    width={"fit-content"}
                >
                    First
                </Button>
                <Button
                    onClick={() => {
                        setPageNo((pre) => {
                            if (pre > 1) {
                                return pre - 1;
                            } else {
                                return pre;
                            }
                        });
                    }}
                >
                    {"<<"}
                </Button>
                <Text
                    padding={"0.5rem 1rem"}
                    border={"1px solid gray"}
                    borderRadius={"5px"}
                >
                    {pageNo}
                </Text>
                <Text>/</Text>
                <Text
                    padding={"0.5rem 1rem"}
                    border={"1px solid gray"}
                    borderRadius={"5px"}
                >
                    {totalPages}
                </Text>
                <Button
                    onClick={() => {
                        setPageNo((pre) => {
                            if (pre < totalPages) {
                                return pre + 1;
                            } else {
                                return pre;
                            }
                        });
                    }}
                >
                    {">>"}
                </Button>
                <Button
                    onClick={() => {
                        setPageNo(totalPages);
                    }}
                    padding={"0.5rem 1rem"}
                    width={"fit-content"}
                >
                    Last
                </Button>
            </HStack>
            {data.length && loading ? (
                <TableContainer
                    boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    w={"100%"}
                >
                    <Table
                        variant="simple"
                        colorScheme="facebook"
                        bgColor="white"
                        size={["md", "lg", "lg"]}
                        overflowX={"auto"}
                        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    >
                        <Thead
                            background={"rgb(229 60 94)"}
                            color={"white !important"}
                        >
                            <Tr>
                                <Th color={"white"}>Service Name</Th>
                                <Th color={"white"}>Service Code</Th>
                                <Th color={"white"}>Price</Th>
                                <Th color={"white"}>Edit</Th>
                                <Th color={"white"}>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data?.map((el) => {
                                return (
                                    <ServicesCard
                                        OpenBlockModal={OpenBlockModal}
                                        CloseBlockModal={CloseBlockModal}
                                        key={el._id}
                                        id={el._id}
                                        // isBlock={el.isBlock}
                                        serviceName={el.serviceName}
                                        serviceCode={el.serviceCode}
                                        price={el.price}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
            {!data.length && loading ? (
                <Empty title={"No service found!!!"} />
            ) : (
                <></>
            )}
            {/* Edit User Model */}
            <Modal isOpen={editModal} onClose={CloseBlockModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"30vh"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                        pt={5}
                    >
                        <form
                            style={{ width: "76.5%" }}
                            onSubmit={handleSubmit}
                        >
                            <VStack p={"1.5rem 0.8rem"} align="flex-start">
                                <Input
                                    placeholder="Service Name"
                                    defaultValue={singleService.serviceName}
                                    ref={addServiceNameRef}
                                />
                                <Input
                                    placeholder="Service Code"
                                    defaultValue={singleService.serviceCode}
                                    ref={addServiceCodeRef}
                                />
                                <Input
                                    type={"number"}
                                    placeholder="price"
                                    defaultValue={singleService.price}
                                    ref={addServicePriceRef}
                                />
                                <Button
                                    _hover={{
                                        color: "rgb(218 52 85)",
                                        background: "white",
                                    }}
                                    type="submit"
                                    mt={"2rem !important"}
                                    bg="rgb(218 52 85)"
                                    color={"white"}
                                    border="1.5px solid rgb(218 52 85) !important"
                                    width="full"
                                    outline={"none"}
                                    onClick={handleEditService}
                                >
                                    Edit Service
                                </Button>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* Delete User Modal  */}
            <Modal isOpen={deleteModal} onClose={CloseBlockModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"30vh"}
                        flexDirection={"column"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                    >
                        <Text fontSize={"1.4rem"} fontWeight={"600"}>
                            Are you sure to Delete This service!!!
                        </Text>
                        <HStack>
                            <Button
                                onClick={() => {
                                    handleDelete();
                                }}
                                _hover={{
                                    bgColor: "white",
                                    color: "rgb(220, 20, 60)",
                                }}
                                color={"white"}
                                bgColor={"rgb(220, 20, 60)"}
                                border={
                                    "2.4px solid rgb(220, 20, 60) !important"
                                }
                            >
                                Confirm
                            </Button>
                            <Button
                                color={"rgb(220, 20, 60)"}
                                bg={"white"}
                                border={
                                    "2.4px solid rgb(220, 20, 60) !important"
                                }
                                _hover={{
                                    bgColor: "rgb(220, 20, 60)",
                                    color: "white",
                                }}
                                onClick={CloseBlockModal}
                            >
                                Cancel
                            </Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* Add User Modal  */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"30vh"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                        pt={5}
                    >
                        <form
                            style={{ width: "76.5%" }}
                            onSubmit={handleSubmit}
                        >
                            <VStack p={"1.5rem 0.8rem"} align="flex-start">
                                <Input
                                    placeholder="Service Name"
                                    ref={addServiceNameRef}
                                />
                                <Input
                                    placeholder="Service Code"
                                    ref={addServiceCodeRef}
                                />
                                <Input
                                    type={"number"}
                                    placeholder="price"
                                    ref={addServicePriceRef}
                                />
                                <ChakraSelect ref={addServerRef}>
                                    <option value="server2">Server1</option>
                                    <option value="server1">Server2</option>
                                    <option value="server3">Server3</option>
                                </ChakraSelect>
                                <Button
                                    _hover={{
                                        color: "rgb(218 52 85)",
                                        background: "white",
                                    }}
                                    type="submit"
                                    mt={"2rem !important"}
                                    bg="rgb(218 52 85)"
                                    color={"white"}
                                    border="1.5px solid rgb(218 52 85) !important"
                                    width="full"
                                    outline={"none"}
                                    onClick={handleAddService}
                                >
                                    Add new service
                                </Button>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default Services;
