import {
  Button,
  HStack,
  Heading,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { RoughNotation } from "react-rough-notation";
import { AppContext } from "../../context/AppContext";
import { Navigate } from "react-router-dom";
import GetOtpCard from "../../components/GetOtpCard";
import { Helmet } from "react-helmet";
const GetOtp = () => {
  const { isDark, Token, selectService } = useContext(AppContext);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [cancelBtnRequestInProgress, setCancelBtnRequestInProgress] = useState(false);
  if (selectService.code == undefined || selectService.code == "") {
    return <Navigate to={`/${selectService.server}`} />;
  };
  return (
    <>
      <Helmet>
        <title>Get OTP | Techy India</title>
      </Helmet>
      <VStack
        w={['100%', '100%', '100%', "79%"]}
        bgColor={isDark ? "#1A202C" : "white"}
        p={['2rem 0.8rem', '2rem 1.4rem', '2rem 2rem', "2rem 2rem"]}
        gap={"1rem"}
        align={"start"}
        minH={"85vh"}
      >
        <Heading>
          Generate Otp for
          <RoughNotation
            strokeWidth={2}
            color={"#EDF2F8"}
            type="underline"
            show={true}
          >
            {` ${selectService.service}`}
          </RoughNotation>
        </Heading>
        <VStack w={['100%', '99%', "97%"]} id="otpContainer">
          {/* {selectService.service.toLowerCase().startsWith('winzo') || selectService.service.toLowerCase().startsWith('whatsapp') ? <>
            <GetOtpCard Token={Token} server={selectService.server} selectService={selectService} />
          </> : <> */}
          <GetOtpCard requestInProgress={requestInProgress} cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress} setRequestInProgress={setRequestInProgress} Token={Token} server={selectService.server} selectService={selectService} />
          <GetOtpCard requestInProgress={requestInProgress} cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress} setRequestInProgress={setRequestInProgress} Token={Token} server={selectService.server} selectService={selectService} />
          <GetOtpCard requestInProgress={requestInProgress} cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress} setRequestInProgress={setRequestInProgress} Token={Token} server={selectService.server} selectService={selectService} />
          <GetOtpCard requestInProgress={requestInProgress} cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress} setRequestInProgress={setRequestInProgress} Token={Token} server={selectService.server} selectService={selectService} />
          <GetOtpCard requestInProgress={requestInProgress} cancelBtnRequestInProgress={cancelBtnRequestInProgress} setCancelBtnRequestInProgress={setCancelBtnRequestInProgress} setRequestInProgress={setRequestInProgress} Token={Token} server={selectService.server} selectService={selectService} />
          {/* </>} */}
        </VStack>
      </VStack>
    </>
  );
};

export default GetOtp;
