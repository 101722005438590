import { useContext, useState } from "react";
import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { AppContext } from "./context/AppContext";

function App() {
  const { isDark } = useContext(AppContext);

  return (
    <div
      style={
        isDark
          ? { background: "#0F1624", minHeight: "100vh", color: "white" }
          : { background: "#EDF2F8", minHeight: "100vh", color: "black" }
      }
    >
      {/* <Navbar /> */}
      <AllRoutes />
    </div>
  );
}

export default App;
