import { Button, Td, Tr } from "@chakra-ui/react";
import React from "react";

const DailyStatsCard = ({ sno, serviceName, success, failed }) => {
  return (
    <>
      <Tr>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          fontWeight={600}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          border={"none"}
          fontSize={['0.8rem','1rem','1rem','1rem']}
        >
          {sno}
        </Td>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          fontWeight={600}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          border={"none"}
          fontSize={['0.8rem','1rem','1rem','1rem']}

        >
          {serviceName}
        </Td>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          border={"none"}
          fontSize={['0.8rem','1rem','1rem','1rem']}
          fontWeight={600}
          color={"#4BB543"}
        >
          {success}
        </Td>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          fontWeight={600}
          border={"none"}
          fontSize={['0.8rem','1rem','1rem','1rem']}

          color={"#FC100D"}
        >
          {failed}
        </Td>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          fontWeight={600}
          border={"none"}
        >
          {success + failed}
        </Td>
        <Td
          pt={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          fontWeight={600}
          pb={["0.5rem !important","0.7rem !important","0.7rem !important","0.7rem !important"]}
          border={"none"}
          fontSize={['0.8rem','1rem','1rem','1rem']}

        >
          {((success / (success + failed)) * 100).toFixed(1)}%
        </Td>
      </Tr>
    </>
  );
};

export default DailyStatsCard;