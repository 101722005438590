import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const UserCard = ({ name, email, balance, id, isBlock, OpenBlockModal }) => {
  const navigate = useNavigate();
  const handleNaviagte = () => {
    navigate(`/admin/users/${id}`);
  }
  // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
  return (
    <>
      <Tr >
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{name || "user"}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{email}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{balance || 0}</Td>
        <Td pt={'0.7rem !important'}   pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
          <Text fontWeight={600} onClick={handleNaviagte} _hover={{
              color:'rgb(220, 20, 60)'
          }} cursor={'pointer'}>Details</Text>
        </Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
          {/* <Text>Block</Text> */}
          <Button
            _hover={{
              bgColor: "rgb(220, 20, 60)",
              color: "white",
            }}
            color={"rgb(220, 20, 60)"}
            bgColor={"white"}
            w={"75%"}
            h={"2.35rem"}
            p={0}
            fontSize={"0.97rem"}
            border={"1px solid rgb(220, 20, 60) !important"}
            onClick={() => OpenBlockModal(id, isBlock)}
          >
            {isBlock ? "UnBlock" : "Block"}
          </Button>
        </Td>
      </Tr>
    </>
  );
};

export default UserCard;
