import { Button, HStack, Image, Input, InputGroup, InputRightElement, Text, VStack, useToast,Flex } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react'
import tetherLogo from "../../../assets/tether.svg";
import trxLogo from "../../../assets/trx.svg";
import { AppContext } from '../../../context/AppContext';
import { GetAllCurrency, SubmitTransactionAPI } from '../../../utils/api';
import { FiCopy } from "react-icons/fi";
const Crypto = () => {
    const { Token, setBalance } = useContext(AppContext);
    const toast = useToast();
    const [currentService, setCurrentService] = useState({});
    const [data, setData] = useState([]);
    const inputRef = useRef();
    useEffect(() => {
        GetAllCurrency(Token).then((res) => {
            setData(res.data.data);
            console.log(res.data.data);
        }).catch((err) => {
            // setLoading(true);
            console.log(err, 'error saying');
        });
    }, []);
    const CopyToClipBoard = () => {
        navigator.clipboard
            .writeText("TUcSg8G1kMbpQjEYMxRhTeA7oFJrYiQHkG")
            .then(() => {
                toast({
                    status: "info",
                    title: `Address copied`,
                    isClosable: true,
                    duration: 1000,
                    position: "bottom-right",
                });
            })
            .catch((error) => {
                console.error("Failed to copy text: ", error);
                toast({
                    status: "error",
                    title: `errror in copying address`,
                    isClosable: true,
                    duration: 1000,
                });
            });
    };
    const handleTransaction = () => {
        if (inputRef.current.value == "") {
            toast({
                status: "warning",
                title: "Please Enter Transaction Id",
                isClosable: true,
                duration: 1000,
            });
            return;
        }
        SubmitTransactionAPI(Token, { transactionId: inputRef.current.value, method: 'crypto' })
            .then((res) => {
                console.log(res.data, "successfull");
                setBalance(res.data.balance);
                toast({
                    title: "Congratulations 🎊",
                    status: "success",
                    description: `Your ${res.data.transaction.amount}rs is successfully added`,
                    isClosable: true,
                    duration: 4000,
                });
            })
            .catch((err) => {
                toast({
                    status: "error",
                    title: err.response?.data.message,
                    isClosable: true,
                });
                console.log(err, "saying error");
            });
    };
    return (
        <VStack w={"100%"} mt={['3rem', '2rem', '1rem', '0.1rem']}>
            <Text fontSize={['1.45rem', '1.5rem', '1.6rem', "1.6rem"]} fontWeight={700}>
                Please Select Payment Service
            </Text>
            <VStack w={"100%"} mt={["3rem", "2rem", "1rem", "0.1rem"]}>
          <Flex
            flexWrap={"wrap"}
            gap={"1rem"}
            justify={"center"}
            border={"1px solid transparent"}
            p={"1rem"}
            w={["100%", "90%", "80%", "80%"]}
          >
            {data?.map((el) => (
              <Button
                p={[
                  "1.15rem 0.65rem",
                  "1.2rem 0.65rem",
                  "1.4rem 0.8rem",
                  "1.4rem 0.8rem",
                ]}
                outline={"none !important"}
                rightIcon={
                  <Image
                    src={el?.imgUrl}
                    w={["1.6rem", "1.8rem", "2rem", "2rem"]}
                    h={["1.6rem", "1.8rem", "2rem", "2rem"]}
                    alt={"tether-logo"}
                  />
                }
                onClick={() => {setCurrentService(el);console.log(el)}}
                colorScheme="white"
                fontSize={["1.2rem", "1.3rem", "1.4rem", "1.4rem"]}
                fontWeight={700}
                color={el.color||'black'}
                border={"1px solid black"}
                w={"8.6rem"}
                key={el._id}
              >
                {el.currencyName}
              </Button>
            ))}
          </Flex>
          {currentService.currencyName && (
            <VStack
              mt={"2rem !important"}
              gap={"1.2rem"}
              align={"start"}
              w={["95%", "70%", "65%", "50%"]}
            >
              <HStack
                border={"1px solid transparent"}
                justify={"start"}
                w={"100%"}
                align={["flex-start", "flex-start", "flex-end", "flex-end"]}
                gap={["0.5rem", "0.5rem", "1rem", "1rem"]}
                flexDirection={["column", "column", "row", "row"]}
              >
                <Text
                  pb={"0.1rem"}
                  fontSize={["1.1rem", "1.15rem", "1.2rem", "1.3rem"]}
                  fontWeight={600}
                >
                  Address :-
                </Text>
                <InputGroup w={["100%", "95%", "80%", "70%"]}>
                  <Input
                    pl={["0.2rem", "0.4rem", "1rem", "1rem"]}
                    fontSize={["0.8rem", "0.9rem", "1rem", "1rem"]}
                    value={"TUcSg8G1kMbpQjEYMxRhTeA7oFJrYiQHkG"}
                    readOnly
                  />
                  <InputRightElement>
                    <Button
                      bg={"white !important"}
                      p={"0 !important"}
                      h={"96%"}
                      border={"1px solid gray !important"}
                      color={currentService.color}
                      onClick={CopyToClipBoard}
                    >
                      <FiCopy />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </HStack>
              <HStack
                w={["60%", "50%", "35%", "33%"]}
                justify={"space-between"}
              >
                <Text
                  fontSize={["1.1rem", "1.15rem", "1.2rem", "1.3rem"]}
                  fontWeight={600}
                >
                  Network :-
                </Text>
                <Text
                  fontWeight={700}
                  fontSize={["1.1rem", "1.15rem", "1.2rem", "1.3rem"]}
                  color={currentService.color}
                >
                  TRC20
                </Text>
              </HStack>
              <HStack
                w={["60%", "50%", "35%", "33%"]}
                justify={"space-between"}
              >
                <Text
                  fontSize={["1.1rem", "1.15rem", "1.2rem", "1.3rem"]}
                  fontWeight={600}
                >
                  {`1 ${currentService.currencyName}`} :-
                </Text>
                <Text
                  fontWeight={700}
                  fontSize={["1.1rem", "1.15rem", "1.2rem", "1.3rem"]}
                  w={"37%"}
                  color={currentService.color}
                >
                  {currentService.currencyPrice}
                </Text>
              </HStack>
              <HStack w={"100%"}>
                <Input ref={inputRef} placeholder="Enter transaction ID" />
                <Button
                  _hover={{
                    bgColor: "white",
                    color: "#135638f0",
                  }}
                  w={"28%"}
                  isLoading={false}
                  loadingText={"Please Wait"}
                  spinnerPlacement="end"
                  onClick={handleTransaction}
                  fontSize={"1.1rem"}
                  color={"white"}
                  bgColor={"#135638f0"}
                  border={"2px solid #135638f0 !important"}
                >
                  Submit
                </Button>
              </HStack>
            </VStack>
          )}
        </VStack>
        </VStack>
    )
}

export default Crypto