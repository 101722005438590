import { Button, HStack, Heading, VStack, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { RoughNotation } from "react-rough-notation";
import Select from "react-select";
import Boy from "./Boy";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const SelectService = ({ server = 'server2' }) => {
  const { isDark, servicesData1, setServiceService, servicesData2, server1Discount, server2Discount, server3Discount, servicesData3 } =
    useContext(AppContext);
  const navigate = useNavigate();
  const handleChange = (event) => {
    console.log(event, 'checking code');
    setServiceService({ service: event.label, code: event.value, server: event.server });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/getotp");
  };
  return (
    <>
      <Helmet>
        <title>Dashboard | Techy India</title>
      </Helmet>
      <HStack
        p={"2rem"}
        gap={"5rem"}
        justify={"center"}
        align={"start"}
        bgColor={isDark ? "#1A202C" : "white"}
        minH={"86vh"}
        w={['100%', '100%', '100%', "79%"]}
        flexDirection={["column", "column", "row", "row"]}
      >
        <VStack gap={"1rem"} align={["start"]} w={['95%', '80%', '40%', "30%"]}>
          <Heading>
            Select{" "}
            <RoughNotation
              strokeWidth={2}
              color={"rgb(220, 20, 60)"}
              type="underline"
              show={true}
            >
              Service
            </RoughNotation>{" "}
          </Heading>
          {server1Discount && server == 'server1' && <Text fontWeight={600} color={"rgb(220, 20, 60)"}
          >Congratulation you got discount of {server1Discount}% all service </Text>}
          {server2Discount && server == 'server2' && <Text fontWeight={600} color={"rgb(220, 20, 60)"}
          >Congratulation you got discount of {server2Discount}% all service</Text>}
          {server3Discount && server == 'server3' && <Text fontWeight={600} color={"rgb(220, 20, 60)"}
          >Congratulation you got discount of {server3Discount}% all service</Text>}
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <VStack align={['flex-end', 'flex-end', 'start', "start"]} w={'100%'}>
              <Select
                onChange={handleChange}
                required={true}
                options={server == 'server2' ? servicesData1 : server == 'server3' ? servicesData3 : servicesData2}
                style={{
                  width: "100%",
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(255 227 233 / 63%)",
                    primary: "rgb(231 70 102)",
                  },
                })}
              />
              <Button
                type={"submit"}
                mt={"1.2rem"}
                p={['1.1rem 1.5rem', '1.2rem 1.7rem', '1.3rem 2rem', "1.3rem 2rem"]}
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                fontSize={['1.1rem', '1.1rem', '1.2rem', "1.2rem"]}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
              >
                Submit
              </Button>

            </VStack>

          </form>
        </VStack>
        <Boy />
      </HStack>
    </>
  );
};

export default SelectService;
