import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,

} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { AdminGetAllTransaction, GetAdminSearchTransaction, GetAdminSingleTransaction } from "../../../utils/api";
import Empty from "../../../components/Empty";
import Select from "react-select";
import TransactionsHistoryCard from "../components/TransactionsHistoryCard";
const TransactionsHistory = () => {
  const { AdminToken } = useContext(AppContext);
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searched, setSearched] = useState({});
  let debounceTimer;

  // Define the debounced function
  const debouncedHandleChange = (value) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      GetAdminSearchTransaction(AdminToken, value).then((res) => {
        setSearchData(res.data.data);
      }).catch((err) => {
        console.log(err, 'error in searching');
      })
    }, 1000); // Adjust the delay time as needed
  };
  const handleSearch = (value) => {
    if (value.length < 3) {
      return;
    }
    debouncedHandleChange(value)
  }
  useEffect(() => {
    setLoading(false);
    AdminGetAllTransaction(AdminToken, pageNo, limitNo)
      .then((res) => {
        setLoading(true);
        console.log(res.data.data);
        setData(res.data.data);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo, pageNo]);
  const handleSubmit = (event) => { event.preventDefault() };
  const handleChange = (event) => {
    if (event.value != '') {
      GetAdminSingleTransaction(AdminToken, event.value).then((res) => {
        setSearched(res.data.data);
        console.log(res.data);
      }).catch((err) => {
        console.log(err, 'error saying');
      })
    }
    // console.log(event);
  };
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      <VStack align={'self-end'} w={'100%'}>
        <HStack w={'50%'}>
          <Select
            onChange={handleChange}
            onInputChange={handleSearch}
            options={searchData}
            placeholder="Search Transaction Id"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "rgb(255 227 233 / 63%)",
                primary: "rgb(231 70 102)",
              },
            })}
          />
        </HStack>
        {
          searched._id && <TableContainer
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            w={"100%"}
          >
            <Table
              variant="simple"
              colorScheme="facebook"
              bgColor="white"
              size={["md", "lg", "lg"]}
              overflowX={"auto"}
              boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            >
              <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                <Tr>
                  <Th color={"white"}>Transaction</Th>
                  <Th color={"white"}>Transaction type</Th>
                  <Th color={"white"}>Price</Th>
                  <Th color={"white"}>Email</Th>
                  <Th color={"white"}>Time</Th>
                </Tr>
              </Thead>
              <Tbody>
                <TransactionsHistoryCard
                  email={searched?.userId.email || ''}
                  price={searched.price || 0}
                  transactionId={searched.transactionId}
                  time={searched.time}
                  type={searched?.type || ''}
                />
              </Tbody>
            </Table>
          </TableContainer>
        }
      </VStack>
      {totalPages > 1 && (
        <HStack gap={"1rem"} align={"center"}>
          <ChakraSelect
            onChange={(e) => {
              setLimitNo(Number(e.target.value));
              setPageNo(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
          </ChakraSelect>
          <Button
            onClick={() => {
              setPageNo(1);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            First
          </Button>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre > 1) {
                  return pre - 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {"<<"}
          </Button>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {pageNo}
          </Text>
          <Text>/</Text>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {totalPages}
          </Text>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre < totalPages) {
                  return pre + 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {">>"}
          </Button>
          <Button
            onClick={() => {
              setPageNo(totalPages);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            Last
          </Button>
        </HStack>
      )}
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>Transaction</Th>
                <Th color={"white"}>Transaction type</Th>
                <Th color={"white"}>Price</Th>
                <Th color={"white"}>Email</Th>
                <Th color={"white"}>Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <TransactionsHistoryCard
                    key={el._id}
                    email={el?.userId.email || ''}
                    price={el.price || 0}
                    transactionId={el.transactionId}
                    time={el.time}
                    type={el?.type || ''}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
   
      {!data.length && loading ? <Empty title={"No transaction found!!!"} /> : <></>}
    </VStack>
  );
};

export default TransactionsHistory;
