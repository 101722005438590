import React, { useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import DashboardCard from "../components/DashboardCard";
const Dashboard = () => {
  const { isDark } = useContext(AppContext);
  return (
    <>
      <DashboardCard />
    </>
  );
};

export default Dashboard;
