import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../../context/AppContext";
import {
    Box,
    Button,
    HStack,
    Heading,
    Select,
    Table,
    TableContainer,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { GetAdminSingleUser, GetSingleUserDetails } from "../../../utils/api";
import OtpHistoryCard from "../../../components/OtpHistoryCard";
import Empty from "../../../components/Empty";
import TransactionCard from "../../../components/TransactionCard";
import SingleOtpCard from "../../../components/SingleOtpCard";
const SingleUser = () => {
    const { AdminToken } = useContext(AppContext);
    const [otpHistory, setOtpHistory] = useState([]);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [currentUser, setCurrentUser] = useState('');
    const [pageNo, setPageNo] = useState(1);
    const [limitNo, setLimitNo] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [current, setCurrent] = useState('');
    const { id } = useParams();
    useEffect(() => {
        GetAdminSingleUser(AdminToken, id).then((res) => {
            setCurrentUser(res.data.user.email);
        }).catch((err) => {
            console.log(err, 'error saying');
        })
    }, [])
    useEffect(() => {
        if (current == 'otp') {
            GetSingleUserDetails(AdminToken, { userId: id, render: 'otp' }, pageNo, limitNo).then((res) => {
                console.log(res.data.data, 'my otp data data');
                setCurrent('otp');
                setOtpHistory(res.data.data);

                setTotalPages(res.data.total_pages);
            }).catch((err) => {
                console.log(err, 'error aa gya')
            })
        }
        if (current == 'transaction') {
            GetSingleUserDetails(AdminToken, { userId: id, render: 'transaction' }, pageNo, limitNo).then((res) => {
                console.log(res.data, 'my data');
                setCurrent('otp');
                setOtpHistory(res.data.data);
                setTotalPages(2);
            }).catch((err) => {
                console.log(err, 'error aa gya')
            })
        }
    }, [pageNo, limitNo])
    const handleDetails = (render) => {
        if (render == 'otp') {
            GetSingleUserDetails(AdminToken, { userId: id, render: 'otp' }).then((res) => {
                console.log(res.data, 'my data');
                setCurrent('otp');
                setOtpHistory(res.data.data);
                setTotalPages(res.data.total_pages);
            }).catch((err) => {
                console.log(err, 'error aa gya')
            })
        } else {
            GetSingleUserDetails(AdminToken, { userId: id, render: 'transaction' }).then((res) => {
                setCurrent('transaction');
                setTransactionHistory(res.data.data);
                console.log(res.data, 'my data');
            }).catch((err) => {
                console.log(err, 'error aa gya')
            })
        }
    }
    return (
        <VStack
            gap={"1rem"}
            bg={"white"}
            align={"left"}
            justify={"start"}
            height={"100%"}
            minH={"100vh"}
            w={"79%"}
            p={"1.5rem"}
        >
            <Heading size={"lg"}>User: {currentUser}</Heading>
            <HStack justify={'center'}>
                <Button _hover={{
                    bgColor: "white",
                    color: "rgb(220, 20, 60)",
                }}
                    fontSize={'1.2rem'}
                    p={'1.2rem'}
                    color={"white"}
                    bgColor={"rgb(220, 20, 60)"} minW={'17%'} onClick={() => handleDetails('otp')}>
                    Otp history
                </Button>
                <Button _hover={{
                    bgColor: "white",
                    color: "rgb(220, 20, 60)",
                }}
                    fontSize={'1.2rem'}
                    p={'1.2rem'}
                    color={"white"}
                    bgColor={"rgb(220, 20, 60)"} minW={'17%'} onClick={() => handleDetails('transaction')}>
                    Transaction history
                </Button>
            </HStack>
            {otpHistory.length && current == 'otp' ? (
                <TableContainer
                    id="otpHistory"
                    boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    w={"100%"}
                >
                    <Table
                        variant="simple"
                        colorScheme="facebook"
                        bgColor="white"
                        size={['md', "md", "lg", "lg"]}
                        overflowX={"auto"}
                        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    >
                        <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                            <Tr>
                                <Th color={"white"}>Service</Th>
                                <Th color={"white"}>Price</Th>
                                <Th color={"white"}>Mobile</Th>
                                <Th color={"white"}>Otp</Th>
                                <Th color={"white"}>Time</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {otpHistory?.map((el) => {
                                return (
                                    <SingleOtpCard
                                        key={el._id}
                                        serviceName={el?.serviceId?.serviceName || ""}
                                        otpMessage={el.otpMessage}
                                        time={el.mobileId.time}
                                        price={el.mobileId.price}
                                        mobile={el.mobileId.mobileno}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
            {transactionHistory.length && current == 'transaction' ? (
                <TableContainer
                    id="otpHistory"
                    boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    w={"100%"}
                >
                    <Table
                        variant="simple"
                        colorScheme="facebook"
                        bgColor="white"
                        size={['md', "md", "lg", "lg"]}
                        overflowX={"auto"}
                        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    >
                        <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                            <Tr>
                                <Th color={"white"}>Price</Th>
                                <Th color={"white"}>Transaction Id</Th>
                                <Th color={"white"}>Time</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {transactionHistory?.map((el) => {
                                return (
                                    <TransactionCard
                                        key={el._id}
                                        transactionId={el.transactionId}
                                        time={el.time}
                                        price={el.price}

                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
            {totalPages > 1 && (
                <HStack ml={'auto !important'} w={'50%'} gap={"1rem"} align={"center"}>
                    <Select
                        onChange={(e) => {
                            setLimitNo(Number(e.target.value));
                            setPageNo(1);
                        }}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={60}>60</option>
                        <option value={80}>80</option>
                        <option value={100}>100</option>
                    </Select>
                    <Button
                        onClick={() => {
                            setPageNo((pre) => {
                                if (pre > 1) {
                                    return pre - 1;
                                } else {
                                    return pre;
                                }
                            });
                        }}
                    >
                        {"<<"}
                    </Button>
                    <Text
                        padding={"0.5rem 1rem"}
                        border={"1px solid gray"}
                        borderRadius={"5px"}
                    >
                        {pageNo}
                    </Text>
                    <Text>/</Text>
                    <Text
                        padding={"0.5rem 1rem"}
                        border={"1px solid gray"}
                        borderRadius={"5px"}
                    >
                        {totalPages}
                    </Text>
                    <Button
                        onClick={() => {
                            setPageNo((pre) => {
                                if (pre < totalPages) {
                                    return pre + 1;
                                } else {
                                    return pre;
                                }
                            });
                        }}
                    >
                        {">>"}
                    </Button>
                </HStack>
            )}
            {totalPages == 0 && current != '' ? (
                <Empty title={`${current} history is Empty!!!`} />
            ) : (
                <></>
            )}
        </VStack>
    );
};

export default SingleUser;
