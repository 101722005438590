import {
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
  Text,
  Box
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { AdminDailyStatsAPI } from "../../../utils/api";
import DailyStatsCard from "../components/DailyStatsCard";
import { AppContext } from "../../../context/AppContext";
import Empty from "../../../components/Empty";

const DailyStats = () => {
  const [data, setData] = useState([]);
  const { AdminToken } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [totalFailure, setTotalFailure] = useState(0);
  const toast = useToast()
  useEffect(() => {
    setLoading(false);
    AdminDailyStatsAPI(AdminToken).then((res) => {
      let sortedData = res.data?.data;
      console.log(sortedData)
      sortedData?.sort((a, b) => {
        const aTotal = a.success + a.failed;
        const bTotal = b.success + b.failed;

        if (aTotal !== bTotal) {
          return bTotal - aTotal; // Sort by total (success + failed) in descending order
        } else {
          return b.success - a.success; // If totals are equal, sort by success in descending order
        }
      })
      setData(sortedData);
      let totalSuccess = 0;
      let totalFailure = 0; 
      sortedData?.forEach((el)=>{
        totalSuccess+=el.success;
        totalFailure+=el.failed;
      });
      setTotalSuccess(totalSuccess);
      setTotalFailure(totalFailure);
      setLoading(true);
    }).catch((err) => {
      setLoading(true);
      toast({
        title: err.response?.data?.message
          ? err.response?.data.message
          : err.message,
        isClosable: true,
        status: "error",
      });
    })
  }, [])
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      minH={"100vh"}
      boxSizing={"border-box"}
      w={["97%", "100%", "100%", "79%"]}
      p={["1rem 0.1rem", "1.2rem 1.2rem", "1.5rem 1.5rem"]}
    >
      <HStack fontWeight={600} w={'100%'} justify={'space-between'}>
        <Text>Total Failure <Box as="span" color={"#FC100D"}
        >{totalFailure}</Box></Text>
        <Text>Total Success <Box as="span" color={"#4BB543"}
        >{totalSuccess}</Box></Text>
      </HStack>
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["sm", "md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>SNo</Th>
                <Th color={"white"}>Service</Th>
                <Th color={"white"}>Success</Th>
                <Th color={"white"}>Failed</Th>
                <Th color={"white"}>Total</Th>
                <Th color={"white"}>Success Rate</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el, index) => {
                return (
                  <DailyStatsCard
                    success={el.success}
                    failed={el.failed}
                    serviceName={`${el?.serviceId?.serviceName} ${el?.serviceId?.server=='server1'?'server3':el?.serviceId?.server=='server2'?'server1':'server2'}`}
                    sno={index + 1}
                    key={el._id}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {!data.length && loading ? (
        <Empty title={"No Stats found!!!"} />
      ) : (
        <></>
      )}
    </VStack>
  );
};

export default DailyStats;