import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Td,
    Tr,
    useDisclosure,
  } from "@chakra-ui/react";
  import React from "react";
  
  const ServicesCard = ({ id,serviceName, serviceCode, price, OpenBlockModal }) => {
    // const { isOpen, onOpen,  NewClose=onClose } = useDisclosure();
    return (
      <>
        <Tr>
          <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{serviceName}</Td>
          <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{serviceCode}</Td>
          <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{price}</Td>
          <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}><Button
              _hover={{
                bgColor: "rgb(220, 20, 60)",
                color: "white",
              }}
              color={"rgb(220, 20, 60)"}
              bgColor={"white"}
              w={"75%"}
              h={"2.35rem"}
              p={0}
              fontSize={"0.97rem"}
              border={"1px solid rgb(220, 20, 60) !important"}
              onClick={() => OpenBlockModal(id,"edit",{serviceName,serviceCode,price})}
            >
              Edit
            </Button></Td>
          <Td  pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>
            {/* <Text>Block</Text> */}
            <Button
              _hover={{
                bgColor: "rgb(220, 20, 60)",
                color: "white",
              }}
              color={"rgb(220, 20, 60)"}
              bgColor={"white"}
              w={"75%"}
              h={"2.35rem"}
              p={0}
              fontSize={"0.97rem"}
              border={"1px solid rgb(220, 20, 60) !important"}
              onClick={() => OpenBlockModal(id,"delete")}
            >
              Delete
            </Button>
          </Td>
        </Tr>
      </>
    );
  };
  
  export default ServicesCard;
  