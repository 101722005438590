import React from "react";
import SelectService from "../../components/SelectService";

const Server3 = () => {
  return (
    <>
      <SelectService server={'server3'} />
    </>
  );
};

export default Server3;
