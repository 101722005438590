import React, { useContext, useState } from "react";
import { Formik, Field } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  InputRightElement,
  Text,
  Input,
  VStack,
  InputGroup,
  Button,
  HStack,
  useMediaQuery,
  Link as ChakraLink

} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { SignupICON } from "../../assets/icons";
import { FaTelegram } from 'react-icons/fa';
import { Helmet } from "react-helmet";
const Signup = () => {
  const [data, setData] = useState({ email: "", password: "" });
  const [pass, setPass] = useState("");
  const [show, setShow] = useState(false);
  const [isLargerThan770] = useMediaQuery("(min-width: 770px)");

  const { signInWithGoogle, signupUser } = useContext(AppContext);
  return (
    <>
      <Helmet>
        <title>Signup | Techy India</title>
      </Helmet>
      <HStack
        flexDirection={["column", "column", "row", "row"]}
        minH={"100vh"}
        p={"0 1rem"}
        mb={["1rem", "1rem", 0, 0]}
        justify={"space-between"}
      >
        <SignupICON size={isLargerThan770 ? "40%" : "50%"} />
        <Box
          textAlign="left"
          p={["0rem 0rem", "0rem 1.8rem", "0rem 2.5rem", "0rem 2.5rem"]}
          pt={"1.15rem !important"}
          w={["98%", "85%", "40%", "40%"]}
          margin="auto"
          rounded={"0.3rem"}
          border={"1px solid black"}
          minH={"90vh"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center !important"}
        >
          <Text
            fontSize={["1.5rem", "1.6rem", "1.8rem", "1.8rem"]}
            mb={"1rem"}
            textAlign={"center"}
            fontWeight={500}
          >
            Register
          </Text>
          <Flex justify="space-around">
            <Flex
              align="center"
              bg="white"
              color="black"
              cursor={"pointer"}
              rounded={"0.5rem"}
              w={"90%"}
              p={2}
              justify={"center"}
              onClick={signInWithGoogle}
            // _hover={{ background: "#ffebd7" }}
            >
              <Image
                w={["25px", "28px"]}
                src={"https://img.icons8.com/color/452/google-logo.png"}
              />
              <Text ml={["7px", "10px"]} fontSize={["md"]} fontWeight="bold">
                Sign in with google
              </Text>
            </Flex>
          </Flex>
          <Flex align="center" justify="space-around" mt={5}>
            <Box borderBottom="2px solid gray" w="42%"></Box>
            <Text fontWeight="bold">OR</Text>
            <Box borderBottom="2px solid gray" w="42%"></Box>
          </Flex>
          <Box>
            <Flex align="center" justify="center" my={5}>
              <Box rounded="md" w="90%" pb={0}>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  onSubmit={(values) => {
                    setData(values);
                    console.log(values, "checking values");
                    signupUser(values.email, values.password)
                      .then((res) => { })
                      .catch(() => { });
                  }}
                >
                  {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <VStack spacing={8} align="flex-start">
                        <FormControl
                          position={"relative"}
                          isInvalid={!!errors.email && touched.email}
                        >
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Field
                            as={Input}
                            id="email"
                            name="email"
                            type="email"
                            variant="outline"
                            size="lg"
                            border="2.5px solid black"
                            _hover={{ background: "#ffffff", color: "black" }}
                            validate={(value) => {
                              let error;
                              if (!value) {
                                error = "Required";
                              } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                  value
                                )
                              ) {
                                error = "Invalid email address";
                              }
                              return error;
                            }}
                          />
                          <FormErrorMessage
                            bottom={"-20.5px"}
                            position={"absolute"}
                          >
                            {errors.email}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          marginTop={"1.2rem !important"}
                          isInvalid={!!errors.password && touched.password}
                        >
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <InputGroup position={"relative"}>
                            <Field
                              as={Input}
                              id="password"
                              name="password"
                              type={show ? "text" : "password"}
                              size="lg"
                              border="2.5px solid black"
                              _hover={{ background: "#ffffff", color: "black" }}
                              validate={(value) => {
                                let error;
                                if (!value) {
                                  error = "Required ";
                                } else if (value.length < 7) {
                                  error =
                                    "Password must contain at least 8 characters";
                                }
                                setPass(value);
                                return error;
                              }}
                            />
                            <InputRightElement width="4.5rem">
                              <Center
                                fontSize="30px"
                                pt={2}
                                bg="none"
                                onClick={() => setShow(!show)}
                              >
                                {show ? (
                                  <BiHide style={{ cursor: "pointer" }} />
                                ) : (
                                  <BiShow style={{ cursor: "pointer" }} />
                                )}
                              </Center>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage
                            bottom={"-20.5px"}
                            position={"absolute"}
                          >
                            {errors.password}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          marginTop={"1.2rem !important"}
                          isInvalid={!!errors.confirm && touched.confirm}
                        >
                          <FormLabel htmlFor="confirm">
                            Confirm Password
                          </FormLabel>
                          <InputGroup position={"relative"}>
                            <Field
                              as={Input}
                              id="confirm"
                              name="confirm"
                              type={show ? "text" : "password"}
                              size="lg"
                              border="2.5px solid black"
                              _hover={{ background: "#ffffff", color: "black" }}
                              validate={(value) => {
                                let error;
                                if (!value) {
                                  error = "Required ";
                                } else if (value.length > 5 && pass !== value) {
                                  error = "Password must be same";
                                }
                                return error;
                              }}
                            />
                            <InputRightElement width="4.5rem">
                              <Center
                                fontSize="30px"
                                pt={2}
                                bg="none"
                                onClick={() => setShow(!show)}
                              >
                                {show ? (
                                  <BiHide style={{ cursor: "pointer" }} />
                                ) : (
                                  <BiShow style={{ cursor: "pointer" }} />
                                )}
                              </Center>
                            </InputRightElement>
                          </InputGroup>
                          <FormErrorMessage
                            bottom={"-20.5px"}
                            position={"absolute"}
                          >
                            {errors.confirm}
                          </FormErrorMessage>
                        </FormControl>
                        <Button
                          h="48px"
                          // background: rgb(218 52 85);
                          // color: white;
                          _hover={{
                            color: "rgb(218 52 85)",
                            background: "white",
                          }}
                          type="submit"
                          bg="rgb(218 52 85)"
                          color={"white"}
                          border="1.5px solid rgb(218 52 85) !important"
                          width="full"
                          outline={"none"}
                        >
                          Signup
                        </Button>
                      </VStack>
                    </form>
                  )}
                </Formik>
                <Text textAlign={"center"} mt={"2rem"}>
                  Already have an account?{" "}
                  <Link id="login" to="/login">
                    Login
                  </Link>{" "}
                </Text>
              </Box>
            </Flex>{" "}
          </Box>
        </Box>
        <Box zIndex={10} position={'fixed'} bottom={['0.5rem', '0.9rem', '1rem', '1rem']} right={['0.5rem', '0.9rem', '1rem', '1rem']} fontSize={'3rem'} >
          <ChakraLink href="https://telegram.me/otpbypassxyz" target={"_blank"}>
            <FaTelegram style={{
              color: '#3390EC',
              cursor: 'pointer'
            }} />
          </ChakraLink>
        </Box>
      </HStack>
    </>
  );
};

export default Signup;
