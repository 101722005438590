import {
  VStack,
  Button,
  HStack,
  useToast,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { RoughNotation } from "react-rough-notation";
import { Helmet } from "react-helmet";
import { BsFillCreditCardFill } from "react-icons/bs";
import { MdCheckCircle } from "react-icons/md";
const Recharge = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setClicked(false);
  }, [])
  return (
    <>
      <Helmet>
        <title>Payment | Techy India</title>
      </Helmet>
      <VStack
        gap={"1rem"}
        bg={"white"}
        align={"start"}
        justify={"start"}
        height={"100%"}
        minH={"100vh"}
        w={["97%", "100%", "100%", "79%"]}
        p={"1.5rem"}
      >
        <Heading>
          Add
          <RoughNotation
            strokeWidth={2}
            color={"rgb(220, 20, 60)"}
            type="underline"
            show={true}
          >
            {` money `}
          </RoughNotation>
          to wallet
        </Heading>
        {location.pathname == "/payment" && <VStack w={'90%'} align={'center'} gap={'1rem'}>
          <Text fontSize={['1.45rem', '1.5rem', '1.6rem', "1.6rem"]} fontWeight={700}>Select your payment method</Text>
          <VStack gap={'2rem'} w={['97%', '100%', '100%', '100%']} justify={'center'}>
            <HStack w={['100%', '100%', '100%', '100%']} justify={'center'} >
              <Button
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                w={"7rem"}
                isLoading={false}
                loadingText={"Please Wait"}
                spinnerPlacement="end"
                onClick={() => {
                  navigate('/payment/upi')
                  setClicked(true);
                }}
                fontSize={"1.1rem"}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2px solid rgb(220, 20, 60) !important"}
              >
                UPI
              </Button>
              <Button
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                w={"7rem"}
                isLoading={false}
                loadingText={"Please Wait"}
                spinnerPlacement="end"
                onClick={() => {
                  navigate('/payment/crypto')
                  setClicked(true);
                }}
                fontSize={"1.1rem"}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2px solid rgb(220, 20, 60) !important"}
              >
                Crypto
              </Button>
              <Button
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                w={"7rem"}
                isLoading={false}
                loadingText={"Please Wait"}
                spinnerPlacement="end"
                onClick={() => {
                  navigate('/payment/promocode')
                  setClicked(true);
                }}
                fontSize={"1.1rem"}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2px solid rgb(220, 20, 60) !important"}
              >
                Promocode
              </Button>
            </HStack>
            <Button
              outline={"none !important"}
              _hover={{
                bgColor: "white",
                color: "rgb(220, 20, 60)",
              }}
              rightIcon={<BsFillCreditCardFill />}
              onClick={() => { navigate('/transaction') }}
              colorScheme="white"
              fontSize={"1.1rem"}
              fontWeight={700}
              bgColor={"rgb(220, 20, 60)"}
              color={'white'}
              border={"2px solid rgb(220, 20, 60) !important"}
            >
              Transactions
            </Button>
          </VStack>
          <VStack w={['95%', '95%', '75%', '60%']} align={'start'}>
            <Text fontWeight={600} fontSize={'1.1rem'}>This bonus will be available on recharge and this offer is for lifetime After recharge, contact our customer support and get your bonus</Text>
            <List spacing={3}>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                You will get 3% bonus on recharge of ₹1000
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                You will get 5% bonus on recharge of ₹3000
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                You will get 7% bonus on recharge of ₹5000
              </ListItem>
              {/* You can also use custom icons from react-icons */}
              <ListItem>
                <ListIcon as={MdCheckCircle} color='green.500' />
                You will get 10% bonus on recharge of ₹10000
              </ListItem>
            </List>

          </VStack>
        </VStack>}
        <Outlet />
      </VStack>
    </>
  );
};

export default Recharge;
