import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  HStack,
  useToast,
  Spinner,
  Select,
  Heading,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import Empty from "../../components/Empty";
import { RoughNotation } from "react-rough-notation";
import TransactionCard from "../../components/TransactionCard";
import { GetTransactionAPI } from "../../utils/api";
import { Helmet } from "react-helmet";
const Transaction = () => {
  const { Token } = useContext(AppContext);
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    GetTransactionAPI(Token, pageNo, limitNo)
      .then((res) => {
        setLoading(true);
        setData(res.data.wallet);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo, pageNo]);
  return (
    <>
      <Helmet>
        <title>Transactions | Techy India</title>
      </Helmet>
      <VStack
        gap={"1rem"}
        bg={"white"}
        align={"start"}
        justify={"start"}
        height={"100%"}
        minH={"100vh"}
        w={["97%", "100%", "100%", "79%"]}
        p={"1.5rem"}
      >
        <Heading>
          Transactions
          <RoughNotation
            strokeWidth={2}
            color={"rgb(220, 20, 60)"}
            type="underline"
            show={true}
          >
            {` History`}
          </RoughNotation>
        </Heading>
        {!loading && (
          <VStack justify={"center"} w={"100%"} minH={"50vh"}>
            <Spinner
              thickness="5px"
              speed="0.55s"
              emptyColor="gray.200"
              color="red.400"
              size="xl"
            />
          </VStack>
        )}
        {data.length && loading ? (
          <TableContainer
            id="otpHistory"
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            w={"100%"}
          >
            <Table
              variant="simple"
              colorScheme="facebook"
              bgColor="white"
              size={['md', "md", "lg", "lg"]}
              overflowX={"auto"}
              boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            >
              <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                <Tr>
                  <Th color={"white"}>Amount</Th>
                  <Th color={"white"}>Transaction Id</Th>
                  <Th color={"white"}>Type</Th>
                  <Th color={"white"}>Time</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((el) => {
                  return (
                    <TransactionCard
                      key={el._id}
                      transactionId={el.transactionId}
                      time={el.time}
                      price={el.price}
                      type={el?.type || 'old one'}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )}
        {totalPages > 1 && (
          <HStack gap={"1rem"} align={"center"}>
            <Select
              onChange={(e) => {
                setLimitNo(Number(e.target.value));
                setPageNo(1);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
              <option value={80}>80</option>
              <option value={100}>100</option>
            </Select>
            <Button
              onClick={() => {
                setPageNo((pre) => {
                  if (pre > 1) {
                    return pre - 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              {"<<"}
            </Button>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {pageNo}
            </Text>
            <Text>/</Text>
            <Text
              padding={"0.5rem 1rem"}
              border={"1px solid gray"}
              borderRadius={"5px"}
            >
              {totalPages}
            </Text>
            <Button
              onClick={() => {
                setPageNo((pre) => {
                  if (pre < totalPages) {
                    return pre + 1;
                  } else {
                    return pre;
                  }
                });
              }}
            >
              {">>"}
            </Button>
          </HStack>
        )}

        {!data.length && loading ? (
          <Empty title={"Transaction history is Empty!!!"} />
        ) : (
          <></>
        )}
      </VStack>
    </>
  );
};

export default Transaction;
