import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";

const RequireAuth = ({ children }) => {
  //   const isAuth = useSelector((store) => store.auth.token);
  const { isAuth } = useContext(AppContext);
  const { pathname } = useLocation();
  if (isAuth) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
};

export default RequireAuth;
