import React from "react";
import SelectService from "../../components/SelectService";

const Server2 = () => {
  return (
    <>
      <SelectService server={'server2'} />
    </>
  );
};

export default Server2;
