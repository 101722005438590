import { Img, Text, VStack } from "@chakra-ui/react";
import React from "react";

const AdminCard = ({ color, logo, data }) => {
  return (
    <VStack
      p={"2rem"}
      gap={"1rem"}
      align={"center"}
      justify={"center"}
      minWidth={"220px"}
      maxWidth={"280px"}
      minHeight={"30vh"}
      backgroundColor={`${color}.200`}
      color={`${color}.700`}
      borderRadius={"5px"}
    >
      <Img
        src={logo}
        alt="logo"
        borderRadius={"50%"}
        width={"50px"}
        height={"50px"}
        boxShadow={"rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;"}
      />
      <VStack>
        <Text fontSize={"24px"} fontWeight={"700"}>
          {data.count}
        </Text>
        <Text fontSize={"18px"} fontWeight={"400"}>
          {data.title}
        </Text>
      </VStack>
    </VStack>
  );
};

export default AdminCard;
