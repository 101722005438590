import {
  Td,
  Tr,
} from "@chakra-ui/react";
const TransactionsHistoryCard = ({ transactionId, type, price, email, time }) => {
  return (
    <>
      <Tr>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{transactionId}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{type?.length ? type : 'Old One'}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{price}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{email}</Td>
        <Td pt={'0.7rem !important'} pb={'0.7rem !important'} borderBottom={"1px solid rgb(229 60 94)"}>{time}</Td>
      </Tr>
    </>
  );
};

export default TransactionsHistoryCard;
