import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { FaTelegramPlane, FaMobile, FaServer } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { BsFillCreditCardFill, BsLightningChargeFill } from "react-icons/bs";
import { RiMessage2Fill } from "react-icons/ri";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { FaRupeeSign } from "react-icons/fa";


const ButtonsList = [
  {
    title: "Dashboard",
    icon: <AiFillHome style={{ fontSize: "1.5rem" }} />,
    link: "/",
  },
  {
    title: "OTP Operator1",
    icon: <FaMobile style={{ fontSize: "1.5rem" }} />,
    link: "/server1",
  },
  {
    title: "OTP Operator2",
    icon: <FaMobile style={{ fontSize: "1.5rem" }} />,
    link: "/server2",
  },
  {
    title: "OTP Operator3",
    icon: <FaMobile style={{ fontSize: "1.5rem" }} />,
    link: "/server3",
  },
  {
    title: "Top up balance",
    icon: <BsLightningChargeFill style={{ fontSize: "1.5rem" }} />,
    link: "/payment",
  },
  // {
  //   title: "Transaction",
  //   icon: <BsFillCreditCardFill style={{ fontSize: "1.5rem" }} />,
  //   link: "/transaction",
  // },
  {
    title: "Activation",
    icon: <RiMessage2Fill style={{ fontSize: "1.5rem" }} />,
    link: "/otphistory",
  },
  {
    title: "Refer and earn",
    icon: <FaRupeeSign style={{ fontSize: "1.5rem" }} />,
    link: "/referral",
  },
  {
    title: "Support",
    icon: <FaTelegramPlane style={{ fontSize: "1.5rem" }} />,
    link: "/support",
  },
  { title: "Setting", icon: <IoMdSettings style={{ fontSize: "1.7rem" }} />, link: "/setting" },
];
const LeftSideBar = ({ closeDrawer, status = false }) => {
  const [active, setActive] = useState("");
  const { isDark, setIsAuth, Token } = useContext(AppContext);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const navigate = useNavigate();
  return (
    <VStack w={["100%", "100%", "100%", "21%"]} position={'sticky'} top={'0'}>
      <VStack
        w={["80%", "80%", "80%", "87.5%"]}
        gap={["1.5rem", "2rem", "2rem", "1rem"]}
        pt={"1rem"}
        align={"center"}
      >
        {ButtonsList.map((el) => (
          <Button
            key={el.title}
            outline={"none !important"}
            onClick={() => {
              setActive(el.title);
              navigate(el.link);
              if(status){
                closeDrawer() 
              }
            }}
            _hover={{
              background: "#fafafa !important",
              color: "rgb(220, 20, 60) !important",
            }}
            style={
              active === el.title
                ? {
                  background: "none",
                  color: "rgb(220, 20, 60)",
                }
                : {
                  background: "transparent",
                  color: isDark ? "white" : "black",
                }
            }
            gap={"0.6rem"}
            fontWeight={400}
            rounded={"6rem"}
            border={"none !important"}
            fontSize={"1.15rem"}
            p={"1.4rem 0.6rem"}
            w={"90%"}
            justifyContent={"start"}
            leftIcon={el.icon}
            colorScheme="white"
            color={"black"}
          >
            {el.title}
          </Button>
        ))}
      </VStack>

    </VStack>
  );
};

export default LeftSideBar;
